import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { TmtLoggerService } from 'tmt-logger';

import { TestCase } from 'src/models/testCase';
import { TestRunBaseService } from 'src/services/test-run.base.service';
import { TestRunViewModel } from 'src/models/testRun';
import { TestRunService } from 'src/services/test-run.service';

@Component({
	selector: 'app-test-cases',
	templateUrl: './test-cases.component.html',
	styleUrls: ['./test-cases.component.scss'],
})
export class TestCasesComponent implements OnInit, OnDestroy, AfterViewInit {
	private subscription: Subscription;

	private subscriptiontc: Subscription;

	displayedColumns: string[] = [
		'ItemNo',
		'Name',
		'Type',
		'TestRun.Environment',
		'TestRun.TestResults[0].TestCaseVersion',
		'TestRun.TestResults[0].Result',
		'TroubleReports',
		'TestRun.TestResults[0].ResultComment',
		'TestRun.ExecutionTime',
		'TestRun.RegBy',
	];

	dataSource: MatTableDataSource<TestRunViewModel>;

	@ViewChild(MatPaginator) public set matPaginator(paginator: MatPaginator) {
		if (paginator && this.testCases) this.dataSource.paginator = paginator;
	}

	@ViewChild(MatSort) sort: MatSort;

	maxall = 10;

	testCases: TestRunViewModel[];

	testCaseNoResults: TestCase;

	gettingTestRuns: boolean;

	constructor(
		private testRunService: TestRunService,
		private route: ActivatedRoute,
		private router: Router,
		private loggerService: TmtLoggerService,
		private testRunBaseService: TestRunBaseService,
	) {}

	ngOnInit(): void {
		this.gettingTestRuns = false;
		this.getTestCases();
		// this.getTestCaseNoResult();
	}

	// sorting and paging does not work unless the fetch of test cases also is done in AfterViewInit
	ngAfterViewInit(): void {
		this.getTestCases();
	}

	public getTestCases(): void {
		this.gettingTestRuns = true;
		this.subscription = this.route.url.pipe(switchMap(urlSegment => this.testRunBaseService.getTestRunsByTestCaseUid(urlSegment[1].path))).subscribe(
			testcasedata => {
				this.testCases = testcasedata;
				const dataSource = new MatTableDataSource<TestRunViewModel>(this.testCases);
				// set a new sortingDataAccessor to be able to sort nested objects
				dataSource.sortingDataAccessor = (item, property) => {
					if (item.TestRunType === 410) {
						switch (property) {
							case 'ItemNo':
								return item.TestSpecification.ItemNo;
							case 'Name':
								return item.TestSpecification.Name;
							case 'Type':
								return 'Test specification';
							case 'TestRun.Environment':
								return item.TestRun.Environment;
							case 'TestRun.TestResults[0].TestCaseVersion':
								return item.TestRun.TestResults[0].TestCaseVersion;
							case 'TestRun.TestResults[0].Result':
								return item.TestRun.TestResults[0].Result;
							case 'TroubleReports':
								return item.TestRun.TestResults[0].troubleReports.length;
							case 'TestRun.TestResults[0].ResultComment':
								return item.TestRun.TestResults[0].ResultComment;
							case 'TestRun.ExecutionTime':
								return item.TestRun.ExecutionTime;
							case 'TestRun.RegBy':
								return item.TestRun.RegBy;
							default:
								return item[property];
						}
					} else {
						switch (property) {
							case 'ItemNo':
								return item.TestSuite.ItemNo;
							case 'Name':
								return item.TestSuite.Name;
							case 'Type':
								return 'Test suite';
							case 'TestRun.Environment':
								return item.TestRun.Environment;
							case 'TestRun.TestResults[0].TestCaseVersion':
								return item.TestRun.TestResults[0].TestCaseVersion;
							case 'TestRun.TestResults[0].Result':
								return item.TestRun.TestResults[0].Result;
							case 'TroubleReports':
								return item.TestRun.TestResults[0].troubleReports.length;
							case 'TestRun.TestResults[0].ResultComment':
								return item.TestRun.TestResults[0].ResultComment;
							case 'TestRun.ExecutionTime':
								return item.TestRun.ExecutionTime;
							case 'TestRun.RegBy':
								return item.TestRun.RegBy;
							default:
								return item[property];
						}
					}
				};
				dataSource.sort = this.sort;
				// set a new filterPredicate function to be able to filter nested objects
				dataSource.filterPredicate = (data, filter: string) => {
					const accumulator = (currentTerm, key) => {
						return this.nestedFilterCheck(currentTerm, data, key);
					};
					const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
					const transformedFilter = filter.trim().toLowerCase();
					return dataStr.indexOf(transformedFilter) !== -1;
				};
				this.dataSource = dataSource;
				this.gettingTestRuns = false;
			},
			error => {
				this.loggerService.logError(error);
				this.gettingTestRuns = false;
			},
		);
	}

	// getTestCaseNoResult(): void {
	//   this.subscriptiontc = this.route.url.pipe(switchMap(urlSegment =>
	//     this.testCaseService.getLatestTestCase(urlSegment[1].path)
	//   )).subscribe( tc => {
	//     this.testCaseNoResults = tc;
	//   },
	//       error => {
	//         console.log(error);
	//         this.gettingTestRuns = false;
	//       }
	//   );
	// }

	private nestedFilterCheck(search, data, key) {
		if (typeof data[key] === 'object') {
			for (const k in data[key]) {
				if (data[key][k] !== null) {
					search = this.nestedFilterCheck(search, data[key], k);
				}
			}
		} else {
			search += data[key];
		}
		return search;
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	public getPageSizeOptions(): number[] {
		if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
			return [10, 20, this.dataSource.paginator.length];
		} else {
			return [10, 20];
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public navigateTo(row: any) {
		this.router.navigate(['/testresult/' + row.TestRun.TestResults[0].TestResultUid]);
	}
}
