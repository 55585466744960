import { Injectable } from '@angular/core';

import * as xmlParser from 'fast-xml-parser';

import { EcuInformation } from 'src/models/ecuInformation';
import { EcuParam } from 'src/models/ecuParam';

/**
 * Handles tasks related to parsing files for display in the client.
 */
@Injectable({
	providedIn: 'root',
})
export class FileParserService {
	constructor() {}

	/**
	 * Parses an E2 file.
	 * @param fileString File contents as a file string.
	 * @returns An array of ecu parameters representing the contents of the E2 file.
	 */
	public parseE2(fileString: string | null): EcuParam[] | null {
		if (!fileString) {
			return null;
		}

		let parsedValues: Array<EcuParam> = [];

		const parsedXml = this.parseXml(fileString);

		// If we cannot confirm that the file has fileType "Configuration", assume file is incorrect.
		if (!(parsedXml as any).Root.Meta.FileType || (parsedXml as any).Root.Meta.FileType !== 'Configuration') {
			throw new Error('Invalid format');
		}

		const meta = (parsedXml as any).Root.Meta;
		const ecuName = meta?.EcuName ?? '';
		const ecuFamilyName = meta?.EcuFamilyName ?? '';

		const ecuIds = (parsedXml as any).Root.ECUID.ID;
		if (ecuIds) {
			for (const ecuId of ecuIds) {
				parsedValues.push({
					TypeUid: '',
					FileUid: '',
					Name: Number(ecuId.number).toString(16).toUpperCase(),
					Description: ecuId.name,
					Value: ecuId.text,
					EnumValue: '',
					ValueType: '',
					EcuName: ecuName,
					EcuFamily: ecuFamilyName,
				});
			}
		}

		const parameters = (parsedXml as any).Root.Parameters.Parameter;
		if (parameters) {
			for (const parameter of parameters) {
				parsedValues.push({
					TypeUid: '',
					FileUid: '',
					Name: (parameter.ID.hex as number).toString(),
					Description: parameter.Description,
					Value: parameter.Value,
					EnumValue: parameter.EnumValue ?? '',
					ValueType: parameter.Type,
					EcuName: ecuName,
					EcuFamily: ecuFamilyName,
				});
			}
		}

		// Filter duplicate values.
		parsedValues = parsedValues.reduce((accumulator, current) => {
			// Check if the property value has been seen before
			if (!accumulator.some(item => item.Name === current.Name)) {
				// If not, add the current object to the accumulator
				accumulator.push(current);
			}
			return accumulator;
		}, []);

		return parsedValues;
	}

	/**
	 * Parses an XCOM-file.
	 * @param fileString File contents as file string.
	 * @returns Object representing the XCOM file.
	 */
	public parseXcom(fileString: string | null): EcuInformation[] | null {
		const ecuInformation: EcuInformation[] = [];

		if (!fileString) {
			return null;
		}

		/**
		 * If we have no ECU tables, assume file is incorrect.
		 */
		if (!fileString.includes('ECU Identification table')) {
			throw new Error('Invalid format');
		}

		// First, split string on each ECU identification table.
		const sections = fileString.split('ECU Identification table');

		// Get each section, skipping the first to avoid initial metadata.
		for (let i = 1; i < sections.length; ++i) {
			const section = sections[i];

			// Get the ECU-ID from the header of the section.
			const ecuId = section.substring(section.indexOf('(') + 1, section.indexOf(')'));

			// Split on newline, to get each row.
			const items = section.split(/[\r\n]+/g);

			// First two rows of each section contains header and formatting.
			// Last two contain formatting.
			// Therefore, loop all rows excluding these.
			for (let j = 2; j < items.length - 2; j += 1) {
				// Split rows on tab character, to get each entry.
				const fields = items[j].split(/\t/);

				// Sometimes, sections contain a nested "ECU information" table.
				// In that case, create a nested loop and insert all of its information in a new property.
				if (fields[0].search('ECU information table') !== -1) {
					for (let k = j + 2; k < items.length - 2; k += 1) {
						const informationFields = items[k].split(/\t/);
						ecuInformation.push({
							Ecu: ecuId,
							Id: informationFields[0]?.trim().replace(/^(F1)/, ''),
							Value: informationFields[2]?.trim().replace('\u0001', ''),
							Description: fields[1]?.trim(),
						});
					}
					break;
				} else {
					// Set values from the fields. We assume that each line has in order ID => Description => Value.
					ecuInformation.push({
						Ecu: ecuId,
						Id: fields[0]?.trim().replace(/^(F1)/, ''),
						Value: fields[2]?.trim().replace('\u0001', ''),
						Description: fields[1]?.trim(),
					});
				}
			}
		}
		return ecuInformation;
	}

	/**
	 * Default parsing of XML file.
	 * @param fileString File contents as file string.
	 * @returns Object representing the XML file.
	 */
	private parseXml(fileString: string): object {
		const parser = new xmlParser.XMLParser({ parseAttributeValue: true, ignoreAttributes: false, attributeNamePrefix: '', textNodeName: 'text' });
		return parser.parse(fileString);
	}
}
