import { Component } from '@angular/core';

import { AuthService } from 'src/services/auth.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	public title = 'Fenix Test Results';

	public items = [];

	/**
	 * Constructor.
	 * @param authService To access user data.
	 */
	constructor(public authService: AuthService) {}
}
