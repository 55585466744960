import { TargetEnvironment } from './environment.interfaces';

export const environment = {
	production: false,
	baseurl: 'https://api-results.tms.test.scania.com:443/api/',
	reportsurl: 'https://reports.testtools.devtest.gf.aws.scania.com/',
	reportsapiurl: 'https://apireports.tms.test.scania.com/',
	requirementsurl: 'https://polarion-dev.scania.com/polarion/#/',
	environment: TargetEnvironment.Default,
	launchDarklyClientId: '664590c02c638010dc55095f',
	abstractionLayerStagingUrl: 'https://quobkvexmre7blsb7ltpdz2xxi.appsync-api.eu-north-1.amazonaws.com/graphql',
	abstractionLayerReleaseUrl: 'https://5oetsllb2jfelbghdyob2uy2r4.appsync-api.eu-north-1.amazonaws.com/graphql',
	apiStagingUrl: 'https://pfx2yyriv5bz3dwszzs7w5qgni.appsync-api.eu-north-1.amazonaws.com/graphql',
	apiReleaseUrl: 'https://xef6knwn7vhq5h26seu6dh5hf4.appsync-api.eu-north-1.amazonaws.com/graphql',
	logConfig: {
		application: 'resultsweb',
		env: 'dev',
		graphqlUrl: 'https://kftht5tfp5b6tfwm6kzqa7ijzu.appsync-api.eu-north-1.amazonaws.com/graphql',
		logLevel: 2,
		console: true,
		debounceTime: 5000,
	},
};
export const Config = {
	colaConfig: {
		disabled: false,
		redirectUrl: 'https://testtools.testtools.devtest.gf.aws.scania.com/login',
	},
};
