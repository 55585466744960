<div class="row">
	<div id="divTestSuiteDetails" class="col-md-12">
		<h4>Details for test suite {{ testSuite?.ItemNo }} - {{ testSuite?.Name }}</h4>
		<table class="table">
			<tbody>
				<tr>
					<td width="20%">Test suite UID</td>
					<td class="bold">{{ testSuite?.TestSuiteUid }}</td>
				</tr>
				<tr>
					<td>Version number</td>
					<td>
						<mat-form-field class="dropdown-padding">
							<select matNativeControl [ngModel]="selectedVersion" (change)="getVersion($event.target.value)">
								<option *ngFor="let version of testSuite?.Versions" value="{{ version.VersionNo }}">
									<span *ngIf="!version.ValidTo">
										&nbsp;{{ version.VersionNo }}&nbsp;&nbsp;&nbsp;&nbsp;({{ version.RegTime | date: 'yyyy-MM-dd HH:mm:ss' }} - {{ version.RegBy.toUpperCase() }}) latest
									</span>
									<span *ngIf="version.ValidTo">
										&nbsp;{{ version.VersionNo }}&nbsp;&nbsp;&nbsp;&nbsp;({{ version.RegTime | date: 'yyyy-MM-dd HH:mm:ss' }} - {{ version.RegBy.toUpperCase() }})
									</span>
								</option>
							</select>
						</mat-form-field>
					</td>
				</tr>
				<tr>
					<td>Creation time for this version</td>
					<td class="bold">{{ testSuite?.RegTime | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
				</tr>
				<tr>
					<td>Owner</td>
					<td class="bold">{{ testSuite?.Owners?.join(', ') }}</td>
				</tr>
				<tr>
					<td>UF</td>
					<td>
						<table class="no-margin bold">
							<tr *ngFor="let uf of testSuite?.UFs">
								<td width="70px" class="no-padding">UF{{ uf.UFNumber }}</td>
								<td class="no-padding">{{ uf.Name }}</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr>
					<td>Product properties</td>
					<td>
						<table class="no-margin bold">
							<tr *ngFor="let productProp of testSuite?.ProductProperties">
								<td width="200px" class="no-padding">{{ productProp.Name }}</td>
								<td class="no-padding">Category: {{ productProp.ProductCategoryName }}</td>
							</tr>
						</table>
					</td>
				</tr>
				<tr>
					<td>Offboard components</td>
					<td class="bold">
						<span *ngIf="offboardComponentValues?.length > 0">{{ offboardComponentValues }}</span>
					</td>
				</tr>
				<tr>
					<td style="vertical-align: top">Description</td>
					<td><div [innerHTML]="testSuite?.DescriptionSafeHtml"></div></td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
