import { Injectable } from '@angular/core';

import { Subject, shareReplay } from 'rxjs';

import { EnvironmentConfig } from 'tmt-logger';

import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AppConfigService {
	private logConfigSubject = new Subject<EnvironmentConfig>();

	public logConfig$ = this.logConfigSubject.asObservable().pipe(shareReplay(1));

	private configuration: any;

	constructor() {}

	public load() {
		this.configuration = environment;
		this.logConfigSubject.next(this.configuration.logConfig);
	}

	public getConfig(): any {
		return this.configuration;
	}
}
