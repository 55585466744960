import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TestSuite, TestSuiteAlternative } from 'src/models/testRun';
import { TestCase } from 'src/models/testCase';

import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export abstract class TestSuiteBaseService extends BaseAPIService {
	public abstract getTestSuiteDetails(testSuiteUid: string, testSuiteVersion: number): Observable<TestSuite>;

	public abstract getLatestTestSuiteDetails(testSuiteUid: string): Observable<TestSuite>;

	public abstract getTestCasesForTestSuite(testSuiteUid: string, testSuiteVersion: number): Observable<Array<TestCase>>;

	public abstract getAlternativesForTestSuite(testSuiteUid: string, testSuiteVersion: number): Observable<Array<TestSuiteAlternative>>;

	public abstract getTestSuite(uid: string, testSuiteVersion: number): Observable<TestSuite>;

	public abstract getTestSuitesForTestSuite(testSuiteUid: string, testSuiteVersion: number): Observable<Array<TestSuite>>;

	public abstract getLatestTestSuite(uid: string): Observable<TestSuite>;

	public abstract getTestCasesForLatestTestSuite(testSuiteUid: string): Observable<Array<TestCase>>;

	public abstract getAlternativesForLatestTestSuite(testSuiteUid: string): Observable<Array<TestSuiteAlternative>>;

	public abstract getTestSuitesForLatestTestSuite(testSuiteUid: string): Observable<Array<TestSuite>>;
}
