import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { TmtLoggerService } from 'tmt-logger';

import { TestCase } from 'src/models/testCase';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';

interface FlatNode {
	expandable: boolean;
	name: string;
	version: number;
	operator: string;
	value: string;
	parentuid: string;
	regTime: Date;
	regBy: string;
	TCUID: string;
	level: number;
}

@Component({
	selector: 'app-test-suite-testcases',
	templateUrl: './test-suite-testcases.component.html',
	styleUrls: ['./test-suite-testcases.component.scss'],
})
export class TestSuiteTestcasesComponent implements OnChanges {
	testCases: TestCase[];

	@Input() testSuiteUid: string;

	@Input() testSuiteVersion: number;

	displayedColumns: string[] = ['Name', 'Version', 'RegTime', 'RegBy', 'TCUID', 'GoTo'];

	gettingTestCases: boolean = false;

	private transformer = (node: TestCase, level: number) => {
		return {
			expandable: !!node.TestCases && node.TestCases.length > 0,
			itemno: node.ItemNo,
			name: node.Name,
			operator: node.Operator,
			value: node.Value,
			version: node.Version,
			parentuid: node.ParentUID,
			regTime: node.RegTime,
			regBy: node.RegBy,
			TCUID: node.TCUID,
			level: level,
		};
	};

	treeControl = new FlatTreeControl<FlatNode>(
		node => node.level,
		node => node.expandable,
	);

	treeFlattener = new MatTreeFlattener(
		this.transformer,
		node => node.level,
		node => node.expandable,
		node => node.TestCases,
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	constructor(
		private testSuiteBaseService: TestSuiteBaseService,
		private router: Router,
		private loggerService: TmtLoggerService,
	) {}

	ngOnChanges(): void {
		if (this.testSuiteVersion !== undefined) {
			this.getTestCasesForTestSuite(this.testSuiteUid, this.testSuiteVersion);
		} else {
			this.getTestCasesForLatestTestSuite(this.testSuiteUid);
		}
	}

	private getTestCasesForTestSuite(tsUid: string, tsVersion: number) {
		this.gettingTestCases = true;
		this.testSuiteBaseService.getTestCasesForTestSuite(tsUid, tsVersion).subscribe(
			tcs => {
				this.testCases = tcs;
				this.dataSource.data = tcs;
				this.gettingTestCases = false;
			},
			error => {
				this.loggerService.logError(error), (this.gettingTestCases = false);
			},
		);
	}

	private getTestCasesForLatestTestSuite(tsUid: string) {
		this.gettingTestCases = true;
		this.testSuiteBaseService.getTestCasesForLatestTestSuite(tsUid).subscribe(
			tcs => {
				this.testCases = tcs;
				this.dataSource.data = tcs;
				this.gettingTestCases = false;
			},
			error => {
				this.loggerService.logError(error), (this.gettingTestCases = false);
			},
		);
	}

	// applyFilter(event: Event) {
	//   const filterValue = (event.target as HTMLInputElement).value;
	// }

	// getPageSizeOptions(): number[] {
	//   return [0];
	// }

	public navigateTo(row: any) {
		if (row !== undefined) {
			this.router.navigateByUrl('/testcasedetails?uid=' + row?.TCUID + '&version=' + row?.version);
		}
	}
}
