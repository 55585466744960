import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { filteredStats } from 'src/models/filteredStats';
import { OverviewItem, OverViewSeries } from 'src/models/overview';
import { environment } from 'src/environments/environment';

import { BaseAPIService } from './base-api.service';

@Injectable({
	providedIn: 'root',
})
export class OverviewService extends BaseAPIService {
	constructor(private http: HttpClient) {
		super();
	}

	public getTotalResultsDistributedOnInputType(): Observable<Array<OverviewItem>> {
		return this.http.get<Array<OverviewItem>>(`${environment.baseurl}overview/testcase/inputtype`, { withCredentials: true }).pipe(catchError(this.handleError));
	}

	public getOverviewData(): Observable<Array<OverViewSeries>> {
		return this.http.get<Array<OverViewSeries>>(`${environment.baseurl}overview`, { withCredentials: true }).pipe(catchError(this.handleError));
	}

	public getOverviewDataWithFilter(SelectedFilters: filteredStats): Observable<Array<OverViewSeries>> {
		return this.http
			.get<
				Array<OverViewSeries>
			>(`${environment.baseurl}overview/filter/?groupowner=${SelectedFilters.groupOwner}&testlevel=${SelectedFilters.testLevel}&testedbygroup=${SelectedFilters.testGroup}`, { withCredentials: true })
			.pipe(catchError(this.handleError));
	}
}
