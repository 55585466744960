import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { TestSpecification } from 'src/models/testSpecification';

import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export abstract class TestSpecificationBaseService extends BaseAPIService {
	public abstract getLatestTestSpecification(uid: string): Observable<TestSpecification>;

	public abstract getTestSpecification(uid: string, testSpecificationVersion: number): Observable<TestSpecification>;

	public abstract getTestSpecificationDetails(testSpecificationUid: string, testSpecificationVersion: number): Observable<TestSpecification>;

	public abstract getLatestTestSpecificationDetails(testSpecificationUid: string): Observable<TestSpecification>;
}
