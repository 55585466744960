import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { History } from 'src/models/history';

import { BaseAPIService } from './base-api.service';

@Injectable({
	providedIn: 'root',
})
export class HistoryService extends BaseAPIService {
	constructor(private http: HttpClient) {
		super();
	}

	public getHistory(parentUid: string, parentTypeID: number, fieldName: string): Observable<Array<History>> {
		return this.http.get<Array<History>>(`${environment.baseurl}history/${parentUid}/${parentTypeID}/${fieldName}`, { withCredentials: true }).pipe(catchError(this.handleError));
	}
}
