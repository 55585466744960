import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseAPIService } from 'src/services/base-api.service';
import { environment } from 'src/environments/environment';
import { VehicleName } from 'src/models/vehicleName';

@Injectable({
	providedIn: 'root',
})
export class VehiclesFilterService extends BaseAPIService {
	constructor(private http: HttpClient) {
		super();
	}

	public getVehicleNames(): Observable<VehicleName[]> {
		return this.http.get<Array<VehicleName>>(`${environment.baseurl}vehicles/`, { withCredentials: true }).pipe(catchError(this.handleError));
	}
}
