import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { TreeNode } from 'primeng/api';
import { TmtLoggerService } from 'tmt-logger';

import { TestRunViewModel } from 'src/models/testRun';
import { VehicleName } from 'src/models/vehicleName';
import { User } from 'src/models/users';
import { FilterCache } from 'src/models/filterCache';
import { TestRunBaseService } from 'src/services/test-run.base.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TestResultsTableService {
	private unfilteredTable: TestRunViewModel[];

	public isLoadingTable = new BehaviorSubject<boolean>(true);

	public testrunsTable = new BehaviorSubject<TestRunViewModel[]>([]);

	public ecuSwIdentifiers$ = new BehaviorSubject<TreeNode[]>([]);

	public environment = environment.environment;

	public filterSettings: FilterCache;

	constructor(
		private testRunService: TestRunBaseService,
		private loggerService: TmtLoggerService,
	) {}

	// This will apply all "soft" filters, they will NOT change the
	// base table, which is based on two dates (from and end)
	public ApplyFilters(filterSettings: FilterCache) {
		const filter = this.unfilteredTable;
		// TODO Commented out the filters for now, wait for decision if they are to be implemented for Stockholm.
		// if (this.environment !== TargetEnvironment.Hero) {
		//   filter = this.FilterByUsername(filter, filterSettings.usersInOrg);
		//   filter = this.FilterByVehicle(filter, filterSettings.vehicle);
		//   filter = this.FilterByEcuSwIdentifier(filter, filterSettings.ecuSwIdentifiers);
		// }
		// after applying filters the data has lost its sorting, so sort descending on execution time

		this.testrunsTable.next(
			filter.slice().sort((n1, n2) => {
				if (n1.TestRun.ExecutionTime > n2.TestRun.ExecutionTime) {
					return -1;
				}
				if (n1.TestRun.ExecutionTime < n2.TestRun.ExecutionTime) {
					return 1;
				}
				return 0;
			}),
		);
	}

	// This will make a new "base" table, which we will apply all filters to.
	public SetTable(filterSettings: FilterCache) {
		this.filterSettings = filterSettings;
		this.loggerService.logDebug('set table');
		this.testRunService
			.getTestRunsByDate(filterSettings.startDate, filterSettings.endDate)
			.pipe(take(1))
			.subscribe(tr => {
				this.loggerService.logDebug('set table2');
				this.unfilteredTable = tr;
				// update source for the ecu identifier list
				this.mapEcuSwIdentifiers();
				this.ApplyFilters(this.filterSettings);
			});
	}

	//#region Filter Functions. Remember to use them in ApplyFilters!
	private FilterByVehicle(table: TestRunViewModel[], selectedVehicle: VehicleName) {
		this.filterSettings = { ...this.filterSettings, vehicle: selectedVehicle };
		if (!selectedVehicle || selectedVehicle?.name === 'All') {
			return table;
		}
		return table.filter(r => r.TestRun.Environment === selectedVehicle.name);
	}

	// apply ecu sw identifier filters on the table source.
	private FilterByEcuSwIdentifier(table: TestRunViewModel[], selectedEcuSwIdentifiers: TreeNode[]) {
		this.filterSettings = { ...this.filterSettings, ecuSwIdentifiers: selectedEcuSwIdentifiers };
		if (!selectedEcuSwIdentifiers || selectedEcuSwIdentifiers.length === 0) {
			return table;
		}
		const result: TestRunViewModel[] = [];
		// return the filtered table based on the ecu identifier selection
		selectedEcuSwIdentifiers
			.filter(x => x.children === undefined)
			.map(x => x.data)
			.forEach(identifier => {
				table
					.filter(x => x.EcuAndSwIdentifiers?.length > 0)
					.forEach(element => {
						const match = element.EcuAndSwIdentifiers.filter(x => x.SwIdentifiers.includes(identifier));
						if (match.length > 0 && !result.includes(element)) {
							result.push(element);
						}
					});
			});
		return result;
	}

	private FilterByUsername(table: TestRunViewModel[], users: User[]) {
		let tempFilter = new Array<TestRunViewModel>();
		let newFilter = new Array<TestRunViewModel>();
		if (users.length === 0) {
			return [];
		}
		users.forEach(user => {
			const tmpUsers = table.filter(res => res.TestRun.RegBy.toUpperCase() === user.username.toUpperCase());
			tempFilter = tempFilter.concat(tmpUsers);
			// If user has changed organization we need to filter out unique items so we only show the test run once in table.
			newFilter = tempFilter.filter(this.onlyUnique);
		});
		return newFilter;
	}
	//#endregion

	// used for filtering unique items in array
	private onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	private mapEcuSwIdentifiers() {
		// select ecu with sw identifier array
		const ecuAndSwIdentifiers = this.unfilteredTable.reduce((a, o) => (o.EcuAndSwIdentifiers?.length > 0 && a.push(o.EcuAndSwIdentifiers), a), []);

		// get unique ecu and add it to new array
		const uniqueEcus = <string[]>[...new Set(ecuAndSwIdentifiers?.map(x => x.Name))];

		// construct a tree node structure to be used for the tree select component
		const ecuTreeNode: TreeNode[] = uniqueEcus.map(x => {
			return { label: x, data: x, key: x, children: [] };
		});

		ecuTreeNode.forEach(element => {
			ecuAndSwIdentifiers
				?.filter(x => x.Name === element.data)
				.forEach(y => {
					// check if it already exists in the tree node
					if (element.children.find(x => x.data === y.SwIdentifiers)) {
						return;
					} else {
						// add to the children list if the identifier doesn't exist. \n is used for styling identifiers to new line.
						element.children.push({ label: y.SwIdentifiers.replaceAll(',', '\n'), data: y.SwIdentifiers, key: y.SwIdentifiers });
					}
				});
		});

		this.ecuSwIdentifiers$.next(ecuTreeNode);
	}
}
