import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router

import { StartPageComponent } from 'src/modules/start-page/start-page.component';

const routes: Routes = [{ path: '', component: StartPageComponent, pathMatch: 'full' }]; // sets up routes constant where you define your routes

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
