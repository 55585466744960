import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { REQUIREMENTS_BY_TEST_CASE_QUERY, REQUIREMENTS_QUERY } from 'src/graphql/queries/abstraction-layer-queries';
import { RequirementWithTestAnalysis } from 'src/models/requirement';

import { ApolloService } from './apollo.service';
import { RequirementBaseService } from './requirement.base.service';

@Injectable({ providedIn: 'root' })
export class RequirementGraphQLService extends RequirementBaseService {
	constructor(private apolloService: ApolloService) {
		super();
	}

	public getRequirementsByTestCase(testCaseUid: string, testCaseVersion: number): Observable<RequirementWithTestAnalysis[]> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsRequirementsWithTestAnalysisByTestCase: RequirementWithTestAnalysis[] }>({
					query: REQUIREMENTS_BY_TEST_CASE_QUERY,
					variables: { TestCaseUid: testCaseUid, TestCaseVersion: testCaseVersion },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsRequirementsWithTestAnalysisByTestCase) {
					return JSON.parse(JSON.stringify(result.data.resultsRequirementsWithTestAnalysisByTestCase));
				} else {
					return <RequirementWithTestAnalysis[]>[];
				}
			}),
			shareReplay(1),
		);
	}

	public getRequirementsByResult(testResultUid: string): Observable<RequirementWithTestAnalysis[]> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsRequirementsWithTestAnalysis: RequirementWithTestAnalysis[] }>({
					query: REQUIREMENTS_QUERY,
					variables: { TestResultUid: testResultUid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsRequirementsWithTestAnalysis) {
					return JSON.parse(JSON.stringify(result.data.resultsRequirementsWithTestAnalysis));
				} else {
					return <RequirementWithTestAnalysis[]>[];
				}
			}),
			shareReplay(1),
		);
	}
}
