<div class="row">
	<span *ngIf="!resultsExist">
		<span class="scania-icon-info xs"></span>
		<span class="bold">You are not authorized to view these test results</span>
	</span>
	<div class="col-md-12" [ngClass]="{ loader: gettingTestRuns }"></div>
	<div [hidden]="!resultsExist || gettingTestRuns">
		<div class="col-md-9">
			@if (environment !== TargetEnvironment.Hero) {
				<div class="col-md-4">
					<a href="{{ reportsurl }}reports?testRunUid={{ testRun?.TestRun.TestRunUid }}" target="_blank">
						<u>
							<h5 class="scania-link no-margin" title="Link to Fenix Test Reports Web site">
								Create report for this test run
								<span class="scania-icon-report sm" style="padding-bottom: 40px"></span>
							</h5>
						</u>
					</a>
				</div>
			}
			<div style="text-align: right">
				<span
					*ngIf="testRun?.TestRunType === 410; else elseBlockListAll"
					[routerLink]="['/testspecification/', testRun?.TestSpecification?.UID]"
					class="scania-link small-font"
					style="align-items: right"
				>
					[View all results for this test specification]
				</span>
				<ng-template #elseBlockListAll>
					<span [routerLink]="['/testsuite/', testRun?.TestSuite?.TestSuiteUid]" class="scania-link small-font" style="align-items: right">[View all results for this test suite]</span>
				</ng-template>
			</div>
		</div>
		<div id="divTestRunHeading" class="col-md-12 no-padding">
			<div class="testrun-heading">
				<span *ngIf="testRun?.TestRunType === 410; else elseBlockHeading">
					<h2>TEST RUN: {{ testRun?.TestSpecification?.ItemNo }} - {{ testRun?.TestSpecification?.Name }}</h2>
				</span>
				<ng-template #elseBlockHeading>
					<h2>TEST RUN: {{ testRun?.TestSuite?.ItemNo }} - {{ testRun?.TestSuite?.Name }}</h2>
				</ng-template>
			</div>
			<div class="testrun-heading-details">
				<span *ngIf="testRun?.TestRunType === 410; else elseBlockDetailHeading">
					&nbsp;(version {{ testRun?.TestSpecification?.Version }}) -
					{{
						testRun?.TestRun?.ExecutionTime
							| date
								: 'yyyy-MM-dd
          HH:mm:ss'
					}}
					- {{ testRun?.TestRun?.Environment | uppercase }} - {{ testRun?.TestRun?.RegBy | uppercase }}
				</span>
				<ng-template #elseBlockDetailHeading>
					&nbsp;(version {{ testRun?.TestSuite?.Version }}) -
					{{
						testRun?.TestRun?.ExecutionTime
							| date
								: 'yyyy-MM-dd
          HH:mm:ss'
					}}
					- {{ testRun?.TestRun?.Environment | uppercase }} - {{ testRun?.TestRun?.RegBy | uppercase }}
				</ng-template>
			</div>
		</div>
		<div id="divTestRun" class="col-md-9">
			<mat-accordion multi>
				<mat-expansion-panel [expanded]="true" class="bottom-margin">
					<mat-expansion-panel-header>
						<mat-panel-title>Test run statistics</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="col-md-12">
						<div id="divTestRunStatistics" class="col-md-9 statistics-chart-margin">
							<ngx-charts-pie-chart
								[view]="view"
								[scheme]="colorScheme"
								[results]="testResultStatistics"
								[gradient]="true"
								[legend]="true"
								[legendTitle]="''"
								[legendPosition]="'below'"
								[labels]="true"
								[trimLabels]="false"
								[doughnut]="false"
								(select)="onSelect($event)"
								(activate)="onActivate($event)"
								(deactivate)="onDeactivate($event)"
							></ngx-charts-pie-chart>
						</div>
						<div class="col-md-3">
							<h5>Input types</h5>
							<table class="bottom-margin-small">
								<tr *ngFor="let input of testRunStatistics?.InputStatistics">
									<td class="no-padding" style="width: 60%">{{ input.Type }}</td>
									<td class="no-padding">{{ input.Count }}</td>
								</tr>
							</table>
							<hr />
							<h5>
								Trouble reports (TR)
								@if (environment !== TargetEnvironment.Hero) {
									<a href="https://help.tms.scania.com/#Create%20Jira%20issue%20and%20link%20it%20to%20a%20Test%20Case" target="_blank">
										<i class="scania-icon-help xs" title="Trouble reports connected to results in Fenix Test Executor. Click to open Help."></i>
									</a>
								}
							</h5>
							<table class="bottom-margin-small">
								<tr>
									<td class="no-padding" style="width: 60%">Number of unique TR</td>
									<td class="no-padding">{{ uniqueTroubleReportNamesInStatistics?.length }}</td>
								</tr>
								<tr>
									<td class="no-padding">Failed without TR</td>
									<td class="no-padding">{{ failedResultsWithoutTroubleReport.length }}</td>
								</tr>
							</table>
						</div>
					</div>
				</mat-expansion-panel>
				<div class="bottom-margin-small">
					<mat-expansion-panel [expanded]="false" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Test results for test run
								<span class="badge badge-default">{{ this.testRun?.TestRun?.TestResults.length }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divTestRunTableHeader" class="col-md-12 no-padding">
							<div class="no-padding col-md-12">
								<!-- <span class="col-md-3 results-filter-empty"></span> -->
								<span *ngIf="includesNotExecuted" class="results-filter-checkboxes">
									<fieldset class="results-filter-fieldset">
										<legend class="results-filter-legend">Show results where Not Executed reason is set to:</legend>
										<span *ngFor="let item of notExecutedTypes; first as isFirst">
											<label
												[class.failedreasonNANPBySystem]="item.name === 'FailedBySystem'"
												[class.failedreasonNANPByUser]="item.name === 'FailedByUser'"
												[class.first-failed-reason]="isFirst"
											>
												<input class="checkbox" type="checkbox" checked="true" (change)="onChangeNotExecutedType(item.name, $event.target.checked)" />
												<i *ngIf="item.name === 'FailedByUser'" class="scania-icon-search-mobile xs"></i>
												{{ item.value }}
												@if (environment !== TargetEnvironment.Hero && item.name === 'FailedBySystem') {
													<a href="https://help.tms.scania.com/#Test%20Suite%20Evaluation" target="_blank"><i class="scania-icon-help xs" title="Click to open Help"></i></a>
												}
											</label>
										</span>
									</fieldset>
								</span>
								<span id="divTestRunFilter" class="col-md-2 align-right no-padding results-filter-text">
									<input type="text" id="txtSearchTestRun" class="form-control" placeholder="Filter..." (keyup)="applyFilter($event)" />
								</span>
							</div>
						</div>
						<div class="mat-elevation-z0">
							<table mat-table [dataSource]="dataSource" matSort>
								<ng-container matColumnDef="TestResultUid">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Test result UID</th>
									<td mat-cell *matCellDef="let element">{{ element?.TestResultUid }}</td>
								</ng-container>
								<ng-container matColumnDef="TestCaseItemNo">
									<th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">ItemNo</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										<i *ngIf="element.FailedReason !== 0 && element.FailedBy !== 'System'" class="scania-icon-search-mobile xs" title="Not executed by user"></i>
										{{ element.TestCaseItemNo }}
									</td>
								</ng-container>
								<ng-container matColumnDef="TestCaseName">
									<th mat-header-cell *matHeaderCellDef mat-sort-header width="20%">Test case name</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										<i *ngIf="element.FailedReason !== 0 && element.FailedBy !== 'System'" class="scania-icon-search-mobile xs" title="Not executed by user"></i>
										{{ element?.TestCaseName }}
										<!-- Use below link when start showing history of test case -->
										<!-- <a routerLink="/testcase/{{element.TestCaseUid}}" routerLinkActive="active">{{element.TestCaseName}}</a> -->
									</td>
								</ng-container>
								<ng-container matColumnDef="Result">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Result</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										{{ element.Result }}
									</td>
								</ng-container>
								<ng-container matColumnDef="TroubleReports">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>
										Trouble reports
										@if (environment !== TargetEnvironment.Hero) {
											<a href="https://help.tms.scania.com/#Create%20Jira%20issue%20and%20link%20it%20to%20a%20Test%20Case" target="_blank">
												<i class="scania-icon-help-white xs" title="Click to open Help"></i>
											</a>
										}
									</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										<i *ngIf="element.troubleReports.length > 0" class="scania-icon-trouble-report xs" title="Trouble reports exist"></i>
									</td>
								</ng-container>
								<ng-container matColumnDef="ResultComment">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Result comment</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										<span *ngIf="element.ResultComment && element.ResultText; else elseBlockResultComment">
											<button
												*ngIf="element.ResultComment && element.ResultText"
												mat-button
												(click)="$event.stopPropagation(); openDialog(element.ResultComment, element.ResultText)"
												class="icon-button"
												title="View result text"
											>
												<div class="icon-button-text">{{ element.ResultComment }}</div>
												<div class="icon-button-icon"><mat-icon>more_horiz</mat-icon></div>
											</button>
										</span>
										<ng-template #elseBlockResultComment>
											<span *ngIf="element.ResultComment">
												{{ element.ResultComment }}
											</span>
										</ng-template>
										<div *ngIf="(element?.PositiveComment !== '' && element?.PositiveComment !== null) || (element?.NegativeComment !== '' && element?.NegativeComment !== null)">
											<table class="table-condensed no-margin">
												<tbody>
													<tr *ngIf="element?.PositiveComment !== '' && element?.PositiveComment !== null">
														<td class="no-padding" title="Positive comment">
															<span class="glyphicon glyphicon-ok-sign text-success"></span>
															{{ element?.PositiveComment }}
														</td>
													</tr>
													<tr *ngIf="element?.NegativeComment !== '' && element?.NegativeComment !== null">
														<td class="no-padding" title="Negative comment">
															<span class="glyphicon glyphicon-remove-sign text-danger"></span>
															{{ element?.NegativeComment }}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</td>
								</ng-container>
								<ng-container matColumnDef="FailedReasonText">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Not executed reason</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										{{ element.FailedReason === 0 ? '' : element.FailedReasonText }}
									</td>
								</ng-container>
								<ng-container matColumnDef="FailedBy">
									<th mat-header-cell *matHeaderCellDef width="10%">Not executed decided by</th>
									<td
										mat-cell
										*matCellDef="let element"
										[ngClass]="{
											failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
											failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
										}"
									>
										{{ element.FailedBy }}
									</td>
								</ng-container>
								<ng-container matColumnDef="FailedReasonComment">
									<th mat-header-cell *matHeaderCellDef>Not executed comment</th>
									<td mat-cell *matCellDef="let element">
										<span *ngIf="element.FailedReasonComment && element.ErrorText; else elseBlockFailedReasonComment">
											<button
												*ngIf="element.FailedReasonComment && element.ErrorText"
												mat-button
												(click)="$event.stopPropagation(); openDialog(element.FailedReasonComment, element.ErrorText)"
												class="icon-button"
												title="View Not executed text"
											>
												<span class="icon-button-text">{{ element?.FailedReasonComment }}</span>
												<span class="icon-button-icon"><mat-icon>more_horiz</mat-icon></span>
											</button>
										</span>
										<ng-template #elseBlockFailedReasonComment>
											<span *ngIf="element.FailedReasonComment">
												{{ element?.FailedReasonComment }}
											</span>
										</ng-template>
									</td>
								</ng-container>
								@if (environment !== TargetEnvironment.Hero) {
									<ng-container matColumnDef="TestCaseAlternativeFailedReasons">
										<th mat-header-cell *matHeaderCellDef>Test case alternative: failed reason</th>
										<td mat-cell *matCellDef="let element">
											<table *ngIf="tcAlternativeFailedReasonsExist">
												<tr *ngFor="let failedReason of element.testCaseAlternativeFailedReasons">
													<td class="no-padding">
														<span
															title="Derived from test case alternative: {{ failedReason.testCaseAlternativeName }} &#xA;with UID: {{
																failedReason.testCaseAlternativeUID
															}}"
														>
															{{ failedReason?.testCaseAlternativeName }}: {{ failedReason?.failedComment }}
															<span class="scania-icon-help xs" style="padding-bottom: 20px"></span>
														</span>
													</td>
												</tr>
											</table>
										</td>
									</ng-container>
								}
								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr
									mat-row
									*matRowDef="let row; columns: displayedColumns"
									[ngClass]="{ failed: row.Result === 'failed', passed: row.Result === 'passed', hovered: row.hovered }"
									(focus)="row.hovered = true"
									(mouseover)="row.hovered = true"
									(blur)="row.hovered = false"
									(mouseout)="row.hovered = false"
									(click)="navigateTo(row)"
									[hidden]="row?.hide === true"
								></tr>
							</table>
							<mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								ECU identification readout
								<span class="badge badge-default">{{ this?.totalEcuReadouts }}</span>
								<span *ngIf="this.originEcuReadout" class="small-font-italic">{{ this?.originEcuReadout }}</span>
								<span *ngIf="this.fileTimeEcuReadout" class="small-font-italic">File creation time: {{ this?.fileTimeEcuReadout | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						@if (environment !== TargetEnvironment.Hero) {
							@if (xcomFiles$ | async; as files) {
								<app-attachments [files]="files"></app-attachments>
							}
						}
						<div id="divEcuReadouts" *ngIf="testRun?.TestRun?.TestRunUid">
							<app-ecu-readout (countEcuReadouts)="showEcuReadoutCounts($event)"></app-ecu-readout>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								E2 parameters
								<span class="badge badge-default">{{ this?.totalEcuParams }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divEcuParams" *ngIf="testRun?.TestRun?.TestRunUid">
							@if (environment !== TargetEnvironment.Hero) {
								@if (e2Files$ | async; as files) {
									<app-attachments [files]="files"></app-attachments>
								}
							}
							<app-ecu-param (countEcuParams)="showEcuParamCounts($event)"></app-ecu-param>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Properties for test run
								<span class="badge badge-default">{{ this?.totalRunProperties }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divProperties" *ngIf="testRun?.TestRun.TestRunUid">
							<app-test-run-properties [runUid]="testRun?.TestRun.TestRunUid" (countRunProperties)="showRunPropertiesCount($event)"></app-test-run-properties>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								SOPS
								<span class="badge badge-default">{{ this?.totalSOPS }}</span>
								<span *ngIf="this.originSOPS" class="small-font-italic">{{ this?.originSOPS }}</span>
								<span *ngIf="this.fileTimeSOPS" class="small-font-italic">File creation time: {{ this?.fileTimeSOPS | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
								<span *ngIf="this.versionSOPS" class="small-font-italic">File version: {{ this?.versionSOPS }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divSOPS" *ngIf="testRun?.TestRun?.TestRunUid">
							<app-sops
								[runUid]="testRun?.TestRun?.TestRunUid"
								(countTotalSOPS)="showSOPSCounts($event)"
								(sopsOrigin)="showSOPSOrigin($event)"
								(sopsFileTime)="showSOPSFileTime($event)"
								(sopsVersion)="showSOPSVersion($event)"
								(sopsFPCs)="showSOPSFPCs($event)"
							></app-sops>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Offboard components
								<span class="badge badge-default">{{ this?.totalOffboardComponents }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divDataComponents" *ngIf="testRun?.TestRun?.TestRunUid">
							@if (environment !== TargetEnvironment.Hero) {
								@if (offboardFiles$ | async; as files) {
									<app-attachments [files]="files"></app-attachments>
								}
							}
							<app-offboard-component [runUid]="testRun?.TestRun?.TestRunUid" (countOffboardComponents)="showOffboardComponentsCount($event)"></app-offboard-component>
						</div>
					</mat-expansion-panel>
				</div>
			</mat-accordion>
		</div>

		<div id="TestRunAdditionalData" class="col-md-3">
			<div id="Environment" class="col-md-12 bottom-margin-small">
				<mat-expansion-panel [expanded]="true">
					<mat-expansion-panel-header>
						<mat-panel-title>Environment for test run</mat-panel-title>
					</mat-expansion-panel-header>
					@if (testRun?.TestRun?.Environment === 'No_Vehicle' || environment === TargetEnvironment.Hero) {
						<span>
							<h4>{{ testRun?.TestRun?.Environment }}</h4>
						</span>
					} @else if (testRun?.TestRun?.Environment !== 'No_Vehicle') {
						<span>
							<a href="https://testvehicle.scania.com/#/view;testCarrier={{ testRun?.TestRun.Environment }}" target="_blank">
								<u>
									<h4
										class="scania-link"
										title="Link to TESTiT Test Vehicles site for this environment. Requires access which can be ordered from OrderIT. Not all environments are represented in TESTiT. "
									>
										{{ testRun?.TestRun?.Environment }}
									</h4>
								</u>
							</a>
						</span>
					}
					<div id="divEnvironment" *ngIf="FPCsSOPS?.length > 0">
						<app-environment [VINNo]="testRun?.TestRun?.VinNo" [FPCs]="FPCsSOPS"></app-environment>
					</div>
				</mat-expansion-panel>
			</div>
			<div id="TestRunDetails" class="col-md-12">
				<mat-expansion-panel [expanded]="true">
					<mat-expansion-panel-header>
						<mat-panel-title>Test run details</mat-panel-title>
					</mat-expansion-panel-header>
					<table id="tableTestRunDetails" class="table-condensed">
						<tbody>
							<tr>
								<td width="40%">Status of test run</td>
								<td width="5px;"></td>
								<td *ngIf="testRunStatusDescription === 'Draft'">
									<span style="color: red">{{ testRunStatusDescription }}</span>
								</td>
								<td *ngIf="testRunStatusDescription === 'Final'">{{ testRunStatusDescription }}</td>
							</tr>
							<tr title="If the test run is going to be used (Valid) or not (Not valid)">
								<td>
									Classification
									<span class="scania-icon-help xs" style="padding-bottom: 20px"></span>
								</td>
								<td width="5px;"></td>
								<td *ngIf="classificationDescription === 'Valid'">{{ classificationDescription }}</td>
								<td *ngIf="classificationDescription === 'NotValid'">
									<span style="color: red">{{ classificationDescription }}</span>
								</td>
							</tr>
							<tr *ngIf="classificationReasonDescription !== 'OK'" title="What causes the test run to not be valid">
								<td>
									Classification reason
									<span class="scania-icon-help xs" style="padding-bottom: 20px"></span>
								</td>
								<td width="5px;"></td>
								<td>{{ classificationReasonDescription }}</td>
							</tr>
							<tr *ngIf="testRun?.TestRun.ClassificationComment.length > 0">
								<td>Classification comment</td>
								<td width="5px;"></td>
								<td>{{ testRun?.TestRun?.ClassificationComment }}</td>
							</tr>
							<tr>
								<td>Test run UID</td>
								<td width="5px;"></td>
								<td>{{ testRun?.TestRun?.TestRunUid }}</td>
							</tr>
							<tr>
								<td>Execution time</td>
								<td width="5px;"></td>
								<td>{{ testRun?.TestRun?.ExecutionTime | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
							</tr>
							<tr>
								<td>Executed by</td>
								<td width="5px;"></td>
								<td style="word-wrap: break-word">{{ testRun?.TestRun?.RegBy }}</td>
							</tr>
							<tr>
								<td>SOP</td>
								<td width="5px;"></td>
								<td>{{ testRun?.TestRun?.SOP }}</td>
							</tr>
							<tr>
								<td>Test period</td>
								<td width="5px;"></td>
								<td>{{ testRun?.TestRun?.TestPeriod }}</td>
							</tr>
						</tbody>
					</table>
					<table class="table-condensed" width="100%">
						<tbody width="100%">
							<span *ngIf="testRun?.TestRunType === 410; else elseBlockType">
								<tr
									class="scania-link"
									[routerLink]="['/testspecificationdetails']"
									[queryParams]="{ uid: testRun?.TestSpecification?.UID, version: testRun?.TestSpecification?.Version }"
									title="Show test specification details page"
								>
									<td class="scania-link" width="40%">Test specification ItemNo</td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ testRun?.TestSpecification?.ItemNo }}</u>
									</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testspecificationdetails']"
									[queryParams]="{ uid: testRun?.TestSpecification?.UID, version: testRun?.TestSpecification?.Version }"
									title="Show test specification details page"
								>
									<td class="scania-link" width="40%">Test specification</td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ testRun?.TestSpecification?.Name }}</u>
									</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testspecificationdetails']"
									[queryParams]="{ uid: testRun?.TestSpecification?.UID, version: testRun?.TestSpecification?.Version }"
									title="Show test specification details page"
								>
									<td class="scania-link">Test specification UID</td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ testRun?.TestSpecification?.UID }}</u>
									</td>
								</tr>
								<tr>
									<td>Test specification version</td>
									<td width="5px;"></td>
									<td>{{ testRun?.TestSpecification?.Version }}</td>
								</tr>
							</span>
							<ng-template #elseBlockType>
								<tr
									class="top-border scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: testRun?.TestSuite?.TestSuiteUid, version: testRun?.TestSuite?.Version }"
									title="Show test suite details page"
								>
									<td class="scania-link" width="40%"><u>Test suite ItemNo</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ testRun?.TestSuite?.ItemNo }}</u>
									</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: testRun?.TestSuite?.TestSuiteUid, version: testRun?.TestSuite?.Version }"
									title="Show test suite details page"
								>
									<td class="scania-link" width="40%"><u>Test suite</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ testRun?.TestSuite?.Name }}</u>
									</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: testRun?.TestSuite?.TestSuiteUid, version: testRun?.TestSuite?.Version }"
									title="Show test suite details page"
								>
									<td class="scania-link"><u>Test suite UID</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ testRun?.TestSuite?.TestSuiteUid }}</u>
									</td>
								</tr>
								<tr>
									<td>Test suite version</td>
									<td width="5px;"></td>
									<td>{{ testRun?.TestSuite?.Version }}</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
</div>
