import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { TestCoverageRequirements } from 'src/models/requirement';

import { BaseAPIService } from './base-api.service';

@Injectable({
	providedIn: 'root',
})
export class SpecificationService extends BaseAPIService {
	constructor(private http: HttpClient) {
		super();
	}

	public getTestSpecification(specificationId: string, irmaVersion?: number): Observable<TestCoverageRequirements> {
		const result = irmaVersion
			? `${environment.baseurl}specifications/${specificationId}/softwares/?irmaVersion=${irmaVersion}`
			: `${environment.baseurl}specifications/${specificationId}/softwares`;
		return this.http.get<TestCoverageRequirements>(result, { withCredentials: true }).pipe(catchError(this.handleError));
	}
}
