import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { User } from 'src/models/users';
import { environment } from 'src/environments/environment';

import { BaseAPIService } from './base-api.service';

@Injectable({
	providedIn: 'root',
})
export class UsersAndGroupsService extends BaseAPIService {
	constructor(private http: HttpClient) {
		super();
	}

	public getUser(): Observable<User> {
		return this.http.get<User>(`${environment.baseurl}user/`, { withCredentials: true }).pipe(catchError(this.handleError));
	}

	private getUsers(): Observable<Array<User>> {
		return this.http.get<Array<User>>(`${environment.baseurl}users/`, { withCredentials: true }).pipe(catchError(this.handleError));
	}

	private getUsersRaw(): Observable<string> {
		return this.http.get(`${environment.baseurl}userRaw/`, { responseType: 'text', withCredentials: true }).pipe(catchError(this.handleError));
	}

	private getUsersFromSameGroup(): Observable<Array<User>> {
		return this.http.get<Array<User>>(`${environment.baseurl}usersInSameOrganization/`, { withCredentials: true }).pipe(catchError(this.handleError));
	}

	private getAllUsersFromOrganization(organization: string): Observable<Array<User>> {
		return this.http.get<Array<User>>(`${environment.baseurl}usersInOrganization/${organization}`, { withCredentials: true });
	}

	private getAllGroups(): Observable<Array<string>> {
		return this.http.get<Array<string>>(`${environment.baseurl}allGroups/`, { withCredentials: true }).pipe(catchError(this.handleError));
	}

	private getAllUsersGroups(group: string): Observable<Array<User>> {
		return this.http.get<Array<User>>(`${environment.baseurl}usersInGroup/${group}`, { withCredentials: true });
	}

	public getAllUsers(): Observable<Array<User>> {
		return this.http.get<Array<User>>(`${environment.baseurl}allUsers/`, { withCredentials: true });
	}
}
