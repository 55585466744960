import { Component, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewChild } from '@angular/core';

import { TmtLoggerService } from 'tmt-logger';

import { History } from 'src/models/history';
import { HistoryService } from 'src/services/history.service';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
	constructor(
		private historyService: HistoryService,
		public dialogRefHistory: MatDialogRef<HistoryComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private loggerService: TmtLoggerService,
	) {}

	history: History[];

	displayedColumns: string[] = ['Before', 'After', 'RegByUser', 'RegTime'];

	dataSource;

	maxall = 5;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	@ViewChild(MatSort) sort: MatSort;

	// TODO test when backend get real data from Data_History table in TL Result db, for now fake data is delivered from test run component
	ngOnInit(): void {
		this.getHistory(this.dialogData.parentUid, this.dialogData.parentTypeID, this.dialogData.fieldName);
	}

	private getHistory(parentUid: string, parentTypeID: number, fieldName: string) {
		this.historyService.getHistory(parentUid, parentTypeID, fieldName).subscribe(
			data => {
				this.history = data;
				this.dataSource = new MatTableDataSource<History>(data);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
			},
			error => this.loggerService.logError(error),
		);
	}

	public getPageSizeOptions(): number[] {
		if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
			return [5, 10, this.dataSource.paginator.length];
		} else {
			return [5, 10];
		}
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	public close() {
		this.dialogRefHistory.close();
	}
}
