import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { InputStatistic, TestRunStatistics, TestRunViewModel } from 'src/models/testRun';
import { Property } from 'src/models/property';
import {
	COMPLETE_TEST_RUN_QUERY,
	TEST_RUN_PROPERTIES_QUERY,
	TEST_RUN_STATISTICS_QUERY,
	TEST_RUNS_BY_DATE_QUERY,
	TEST_RUNS_BY_TEST_CASE_UID_QUERY,
	TEST_RUNS_BY_TEST_SPECIFICATION_UID_QUERY,
	TEST_RUNS_BY_TEST_SUITE_UID_QUERY,
} from 'src/graphql/queries/abstraction-layer-queries';
import { LaunchDarklyService } from 'src/services/launchdarkly.service';

import { TestRunBaseService } from './test-run.base.service';
import { ApolloService } from './apollo.service';

@Injectable({ providedIn: 'root' })
export class TestRunGraphQLService extends TestRunBaseService {
	error: any;

	result: Array<TestRunViewModel>;

	constructor(
		private apolloService: ApolloService,
		private launchDarklyService: LaunchDarklyService,
	) {
		super();
	}

	public getTestRunsByDate(startDate: string, endDate: string): Observable<TestRunViewModel[]> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsTestRunModelByDates: TestRunViewModel[] }>({
					query: TEST_RUNS_BY_DATE_QUERY,
					variables: { StartDate: startDate, EndDate: endDate },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsTestRunModelByDates) {
					return JSON.parse(JSON.stringify(result.data.resultsTestRunModelByDates));
				} else {
					return <TestRunViewModel[]>[];
				}
			}),
			shareReplay(1),
		);
	}

	public getTestRunStatistics(uid: string): Observable<TestRunStatistics> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsStatisticsForTestRun: TestRunStatistics }>({
					query: TEST_RUN_STATISTICS_QUERY,
					variables: { Uid: uid },
				});
			}),
			map(result => {
				const statistics = result.data.resultsStatisticsForTestRun;
				const testRunStatistics = new TestRunStatistics();
				testRunStatistics.InputStatistics = statistics.InputStatistics.map((inputStat: any) => {
					const inputStatistic = new InputStatistic();
					inputStatistic.Type = inputStat.Type;
					inputStatistic.Count = inputStat.Count;
					return inputStatistic;
				});
				return testRunStatistics;
			}),
			shareReplay(1),
		);
	}

	public getTestRun(uid: string): Observable<TestRunViewModel> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(clients =>
				clients['abstractionLayerClient'].query<{ resultsCompleteTestRun: TestRunViewModel }>({
					query: COMPLETE_TEST_RUN_QUERY,
					variables: { Uid: uid },
				}),
			),
			map(result => {
				if (result.data && (result as any).data.testRunModel) {
					return JSON.parse(JSON.stringify((result as any).data.testRunModel));
				} else if (result.data && (result as any).data.resultsCompleteTestRun) {
					return JSON.parse(JSON.stringify((result as any).data.resultsCompleteTestRun));
				} else {
					return new TestRunViewModel();
				}
			}),
			shareReplay(1),
		);
	}

	public getTestRunProperties(uid: string): Observable<Property[]> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsPropertiesForTestRun: Property[] }>({
					query: TEST_RUN_PROPERTIES_QUERY,
					variables: { Uid: uid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsPropertiesForTestRun) {
					return result.data.resultsPropertiesForTestRun;
				} else {
					return <Property[]>[];
				}
			}),
			shareReplay(1),
		);
	}

	public getTestRunsByTestCaseUid(uid: string): Observable<TestRunViewModel[]> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsTestRunModelsByTestCase: TestRunViewModel[] }>({
					query: TEST_RUNS_BY_TEST_CASE_UID_QUERY,
					variables: { Uid: uid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsTestRunModelsByTestCase) {
					return JSON.parse(JSON.stringify(result.data.resultsTestRunModelsByTestCase));
				} else {
					return <TestRunViewModel[]>[];
				}
			}),
			shareReplay(1),
		);
	}

	public getTestRunsByTestSuiteUid(uid: string): Observable<Array<TestRunViewModel>> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsTestRunModelsByTestSuite: TestRunViewModel[] }>({
					query: TEST_RUNS_BY_TEST_SUITE_UID_QUERY,
					variables: { Uid: uid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsTestRunModelsByTestSuite) {
					return JSON.parse(JSON.stringify(result.data.resultsTestRunModelsByTestSuite));
				} else {
					return <TestRunViewModel[]>[];
				}
			}),
			shareReplay(1),
		);
	}

	public getTestRunsByTestSpecificationUid(uid: string): Observable<Array<TestRunViewModel>> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsTestRunModelsByTestSpecification: TestRunViewModel[] }>({
					query: TEST_RUNS_BY_TEST_SPECIFICATION_UID_QUERY,
					variables: { Uid: uid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsTestRunModelsByTestSpecification) {
					return JSON.parse(JSON.stringify(result.data.resultsTestRunModelsByTestSpecification));
				} else {
					return <TestRunViewModel[]>[];
				}
			}),
			shareReplay(1),
		);
	}
}
