import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { take, tap } from 'rxjs';

import { TmtLoggerService } from 'tmt-logger';

import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { FileMetadata } from 'src/models/fileMetadata';
import { FileService } from 'src/services/file.service';
import { File } from 'src/models/file';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-attachments',
	templateUrl: './attachments.component.html',
	styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit {
	constructor(
		private loggerService: TmtLoggerService,
		private fileService: FileService,
	) {}

	dataSourceFiles;

	attachments: File[];

	baseUrl = environment.baseurl;

	environment = environment.environment;

	TargetEnvironment = TargetEnvironment;

	displayedColumnsForFiles: string[] = ['Name', 'ImportTime'];

	@ViewChild(MatSort) sort: MatSort;

	/**
	 * Input list of files to display.
	 */
	@Input({ required: true }) files: FileMetadata[];

	/**
	 * Initialize table data source of files on init.
	 */
	ngOnInit(): void {
		this.dataSourceFiles = new MatTableDataSource<File>(
			this.files.map(item => {
				return {
					Uid: item.FileUid,
					Name: item.FileName,
					ImportTime: item.CreateDate,
					FileStream: '',
				};
			}),
		);
		this.dataSourceFiles.sort = this.sort;
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSourceFiles.filter = filterValue.trim().toLowerCase();
	}

	/**
	 * Fetches a file, then prompts browser to download it to device.
	 * @param fileUid UID of file to download.
	 */
	public downloadFile(fileUid: string): void {
		this.fileService
			.getFileByFileUid$(fileUid)
			.pipe(
				tap(file => this.fileService.downloadFile(file)),
				take(1),
			)
			.subscribe();
	}
}
