export class SOPS {
	FileUid: string;

	FileName: string;

	VersionControlVersion: number;

	FileTime: Date;

	ImportTime: Date;

	OriginTypeUid: number; // 205 Perforce, 206 UserUpload

	RegBy: string;

	RegTime: Date;

	VINNo: string;

	VehicleName: string;

	FPCs: FPC[];

	XPCs: XPC[];

	FunctionParameters: FunctionParameter[];
}

export class FPC {
	Name: string; // example 1

	Value: string; // example A

	FamilyDescription: string; // example Product class

	VariantDescription: string; // example Truck
}

export class XPC {
	Name: string; // examples: ChassisNo, ASSY_COO, FPC1122

	Value: string;
}

export class FunctionParameter {
	Family: string; //example COO

	ParentName: string; //example COO7

	Name: string; //example ServiceDistVeh

	Value: string;
}
