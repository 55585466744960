import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any) {}

	header: string;

	text: string;

	ngOnInit(): void {
		this.header = this.dialogData.header;
		this.text = this.dialogData.text;
	}
}
