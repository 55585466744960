<div class="col-md-12 no-padding">
	<div class="col-md-10 no-padding">
		<h4>Offboard component data for test run</h4>
	</div>
	<div class="col-md-2 align-right no-padding">
		<div class="col-md-12 no-padding">
			<input type="text" id="txtSearchDataComponent" class="form-control" placeholder="Filter..." (keyup)="applyFilter($event)" />
		</div>
	</div>

	<div>
		<table mat-table [dataSource]="dataSource" matSort>
			<ng-container matColumnDef="OffboardComponent.Value">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Service name</th>
				<td mat-cell *matCellDef="let element">{{ element.OffboardComponent.Value }}</td>
			</ng-container>
			<ng-container matColumnDef="OffboardComponent.Version">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
				<td mat-cell *matCellDef="let element">{{ element.OffboardComponent.Version }}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>

		<mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
	</div>
</div>
