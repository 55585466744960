@if (environment !== TargetEnvironment.Hero) {
	<div>
		<table mat-table [dataSource]="dataSourceFiles" matSort>
			<ng-container matColumnDef="Name">
				<th mat-header-cell *matHeaderCellDef>Download file</th>
				<td mat-cell *matCellDef="let element">
					<a (click)="downloadFile(element.Uid)" (keypress)="downloadFile(element.Uid)" [ngStyle]="{ cursor: 'pointer' }" tabindex="{0}">
						<span class="download-arrow">&#11123;</span>
						{{ element.Name }}
					</a>
				</td>
			</ng-container>
			<ng-container matColumnDef="ImportTime">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Import time</th>
				<td mat-cell *matCellDef="let element">
					{{ element.ImportTime | date: 'yyyy-MM-dd HH:mm:ss' }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumnsForFiles"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsForFiles"></tr>
		</table>
	</div>
}
