import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { OffboardComponentWithFile } from 'src/models/offboardComponent';

import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export abstract class OffboardComponentBaseService extends BaseAPIService {
	public abstract getOffboardComponents(runUid: string): Observable<Array<OffboardComponentWithFile>>;
}
