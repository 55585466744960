import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';

import moment from 'moment';
import { SessionStorageService } from 'ngx-webstorage';
import { TreeNode } from 'primeng/api';

import { User } from 'src/models/users';
import { UsersAndGroupsService } from 'src/services/users-and-groups.service';
import { FilterCache } from 'src/models/filterCache';
import { environment } from 'src/environments/environment';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { VehiclesFilterService } from 'src/modules/start-page/services/vehicles-filter.service';
import { TestResultsTableService } from 'src/modules/start-page/services/test-results-table-service';

import { VehicleName } from './../../../../models/vehicleName';

// used to "model" the dropdown of organizations.
// Display value is what will be displayed and value is the actual value.
export interface DropDownType {
	display: string;
	value: string;
}
@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
	environment = environment.environment;

	TargetEnvironment = TargetEnvironment;

	filterSettings: FilterCache;

	allUsersInfo: User[];

	vehicles: Array<VehicleName> = [{ id: 0, name: 'All' }];

	orgsList: DropDownType[] = [{ display: 'All', value: 'All' }];

	selectedInitialOrgs: DropDownType[];

	groupsList: DropDownType[] = [{ display: 'All', value: 'All' }];

	ecuSWIdentifiers: TreeNode[] = [];

	selectedEcuSWIdentifiers: TreeNode[] = [];

	userGroup = '';

	showOrganizationFilter: boolean;

	constructor(
		private sessionStorage: SessionStorageService,
		private vehiclesFilterServices: VehiclesFilterService,
		private usersAndGroupsService: UsersAndGroupsService,
		private testResultsTableService: TestResultsTableService,
		private router: Router,
	) {}

	ngOnDestroy(): void {
		this.sessionStorage.store('filterSettings', this.filterSettings ?? '');
	}

	ngOnInit(): void {
		// Session storage: store until user closes tab/web browser
		// if we have a filter already in our session storage, take it instead
		if (this.sessionStorage.retrieve('filterSettings')) {
			const filter = this.sessionStorage.retrieve('filterSettings');
			this.filterSettings = { ...filter };
		} else {
			// If we have nothing in our session storage then create new filter settings object.
			// Default filter settings for users without linked organization.
			this.selectedInitialOrgs = [{ display: 'All', value: 'All' }];
			const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
			const endDate = moment().endOf('day').toISOString();
			this.filterSettings = { startDate, endDate, usersInOrg: [], vehicle: this.vehicles[0], selectedOrgs: [this.orgsList[0]], selectedGroup: this.groupsList[0], ecuSwIdentifiers: [] };

			// TODO Commented out the filters for now, wait for decision if they are to be implemented for Stockholm.
			// // Default filter settings for users linked to an organization.
			// if (environment.environment !== TargetEnvironment.Hero) {
			//   this.usersAndGroupsService.getUsersFromSameGroup().pipe(take(1)).subscribe(usersInGroup => {
			//     this.userGroup = usersInGroup[0]?.organization;
			//     if (this.userGroup !== undefined && this.userGroup !== '') {
			//       this.showOrganizationFilter = usersInGroup.length > 0;
			//       let groupValue = { display: this.userGroup + ' (my group)', value: this.userGroup };
			//       this.orgsList.push(groupValue);
			//       const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
			//       const endDate = moment().endOf('day').toISOString();
			//       this.filterSettings = { startDate, endDate, usersInOrg: [], vehicle: this.vehicles[0], selectedOrgs: [this.orgsList[1]], selectedGroup: this.groupsList[0], ecuSwIdentifiers: [] };
			//     }
			//   })
			// }
		}

		// TODO Commented out the filters for now, wait for decision if they are to be implemented for Stockholm.
		// if (environment.environment !== TargetEnvironment.Hero) {
		//   // Get all available usernames in the users groups.
		//   this.usersAndGroupsService.getUsersFromSameGroup().pipe(take(1)).subscribe(usersInGroup => {
		//     this.userGroup = usersInGroup[0]?.organization;
		//     this.showOrganizationFilter = usersInGroup.length > 0;
		//     if (this.userGroup !== undefined && this.userGroup !== '') {
		//       var myOrgInOrgsList = this.orgsList.filter(x => x.display === this.userGroup + ' (my group)');
		//       if (myOrgInOrgsList.length === 0) {
		//         let groupValue = { display: this.userGroup + ' (my group)', value: this.userGroup };
		//         this.orgsList.push(groupValue);
		//       }
		//     }

		//     // get all users from CORE db
		//     this.usersAndGroupsService.getAllUsers().pipe(take(1)).subscribe(users => {
		//       this.allUsersInfo = users;
		//       this.filterSettings = { ...this.filterSettings, usersInOrg: this.allUsersInfo };
		//       //Filter out the organizations so they are unique and minimum 3 letter organizations
		//       let orgs = users.map(usr => usr.organization).filter(s => s.length >= 3).filter((v, i, a) => a.indexOf(v) === i).sort();
		//       let orgsObjects = orgs.filter(group => this.userGroup !== group).map(gr => ({ display: gr, value: gr }));
		//       this.orgsList = this.orgsList.concat(orgsObjects);
		//       this.selectedInitialOrgs = this.filterSettings.selectedOrgs; // needed so right orgs are selected from session when using back button in browser
		//       this.filterFilterSettings(this.filterSettings.selectedGroup, this.filterSettings.selectedOrgs);
		//       this.testResultsTableService.SetTable(this.filterSettings);
		//     })

		//     this.usersAndGroupsService.getAllGroups().pipe(take(1)).subscribe(groups => {
		//       this.groupsList = this.groupsList.concat(groups.map(gr => ({ display: gr, value: gr })));
		//     })
		//   });

		//   this.vehiclesFilterServices.getVehicleNames().pipe(take(1)).subscribe(arr => {
		//     this.vehicles = this.vehicles.concat(arr);
		//   });

		//   // gets the ecu sw identifiers for tree select
		//   this.testResultsTableService.ecuSwIdentifiers$.subscribe(identifiers => {
		//     if (identifiers !== undefined) {
		//       this.ecuSWIdentifiers = identifiers.sort((a, b) => a.label.localeCompare(b.label));
		//       this.selectedEcuSWIdentifiers = this.filterSettings.ecuSwIdentifiers;
		//     }
		//   })

		// }
		// else {
		this.testResultsTableService.SetTable(this.filterSettings);
		//}
	}

	// used for multiple select to set initial value
	private comparer(o1: DropDownType, o2: DropDownType): boolean {
		// if possible compare by object's value property - and not by reference.
		return o1 && o2 ? o1.value === o2.value : o2 === o2;
	}

	private publicUpdateSelectedOrganizations(event: MatSelectChange) {
		this.RemoveQueryString();
		let selectedOrgs = new Array<DropDownType>();
		event.value.forEach(org => {
			const tmp = this.orgsList.filter(o => org.value.includes(o.value));
			selectedOrgs = selectedOrgs.concat(tmp);
		});
		if (selectedOrgs.length === 0) {
			selectedOrgs = selectedOrgs.concat([{ display: 'All', value: 'All' }]);
			this.selectedInitialOrgs = selectedOrgs;
		}
		this.filterSettings = { ...this.filterSettings, selectedOrgs };
		this.filterFilterSettings(this.filterSettings.selectedGroup, selectedOrgs);
		this.testResultsTableService.ApplyFilters(this.filterSettings);
	}

	private UpdateSelectedGroup(group: string) {
		this.RemoveQueryString();
		const selectedGroup = this.groupsList.find(grp => grp.value === group);
		this.filterSettings = { ...this.filterSettings, selectedGroup };
		this.filterFilterSettings(selectedGroup, this.filterSettings.selectedOrgs);
		this.testResultsTableService.ApplyFilters(this.filterSettings);
	}

	private filterFilterSettings(group: DropDownType, org: DropDownType[]) {
		let filteredUsers = this.allUsersInfo;
		filteredUsers = group.value === 'All' ? filteredUsers : filteredUsers.filter(s => s.groups.find(g => g === group.value));
		filteredUsers = org.length === 0 || org[0].value === 'All' ? filteredUsers : filteredUsers.filter(x => org.map(o => o.value).includes(x.organization));
		this.filterSettings = { ...this.filterSettings, usersInOrg: filteredUsers };
		this.sessionStorage.store('filterSettings', this.filterSettings ?? '');
	}

	private UpdateSelectedVehicle(vid: string): void {
		this.RemoveQueryString();
		const vechileId = parseInt(vid, 10);
		// if our vehicle id is 0 (comes if you select the All option), take a dummy (empty) vehicle
		const selectedVeh = vid === '0' ? { name: 'All', id: 0 } : this.vehicles.find(v => v.id === vechileId);
		this.filterSettings = { ...this.filterSettings, vehicle: selectedVeh };
		this.sessionStorage.store('filterSettings', this.filterSettings ?? '');
		this.testResultsTableService.ApplyFilters(this.filterSettings);
	}

	public OnStartDateChange($event) {
		this.RemoveQueryString();
		this.filterSettings = { ...this.filterSettings, startDate: $event.format('YYYY-MM-DD') };
		this.sessionStorage.store('filterSettings', this.filterSettings ?? '');
		this.testResultsTableService.isLoadingTable.next(true);
		this.testResultsTableService.SetTable(this.filterSettings);
	}

	public OnEndDateChange($event: moment.Moment) {
		this.RemoveQueryString();
		const endDate = $event.endOf('day').toISOString();
		this.filterSettings = { ...this.filterSettings, endDate };
		this.sessionStorage.store('filterSettings', this.filterSettings ?? '');
		this.testResultsTableService.isLoadingTable.next(true);
		this.testResultsTableService.SetTable(this.filterSettings);
	}

	private applyEcuFilter() {
		this.RemoveQueryString();
		this.filterSettings = { ...this.filterSettings, ecuSwIdentifiers: this.selectedEcuSWIdentifiers };
		// setting the parent values to undefined because of error in Converting circular structure to JSON
		this.filterSettings.ecuSwIdentifiers.forEach(element => {
			if (element.parent) {
				element.parent = undefined;
			}
			// if(element.children){
			//   element.children.forEach(element => {
			//     // element.parent = undefined;
			//   });
			// }
		});
		this.sessionStorage.store('filterSettings', this.filterSettings ?? '');
		this.testResultsTableService.ApplyFilters(this.filterSettings);
	}

	//Remove querystring for property if filters are being used after search with querystring,
	//otherwise the filters won't work
	private RemoveQueryString(): void {
		if (this.router.url.includes('testrun/property')) {
			const url: string = this.router.url.substring(0, this.router.url.indexOf('testrun/property?'));
			this.router.navigateByUrl(url);
		}
	}
}
