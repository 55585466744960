import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs';

import { TmtLoggerService } from 'tmt-logger';

import { TestCaseBaseService } from 'src/services/test-case.base.service';
import { Step } from 'src/models/step';

@Component({
	selector: 'app-test-case-steps',
	templateUrl: './test-case-steps.component.html',
	styleUrls: ['./test-case-steps.component.scss'],
})
export class TestCaseStepsComponent implements OnChanges, OnDestroy {
	@Input() caseUid: string;

	@Input() caseVersion: number;

	displayedColumns: string[] = ['Step number', 'Precondition', 'Stimuli', 'Expected response', 'Postcondition'];

	dataSource: MatTableDataSource<Step>;

	private subsciption: Subscription;

	steps: Array<Step>;

	constructor(
		private testCaseBaseService: TestCaseBaseService,
		private loggerService: TmtLoggerService,
	) {}

	ngOnDestroy(): void {
		this.subsciption.unsubscribe();
	}

	ngOnChanges() {
		if (this.caseVersion !== undefined) {
			this.getSteps(this.caseUid, this.caseVersion);
		} else {
			this.getLatesSteps(this.caseUid);
		}
	}

	private getSteps(caseUid: string, caseVersion: number) {
		this.subsciption = this.testCaseBaseService.getTestCaseSteps(caseUid, caseVersion).subscribe(
			st => {
				this.steps = st;
				this.dataSource = new MatTableDataSource<Step>(st);
			},
			error => this.loggerService.logError(error),
		);
	}

	private getLatesSteps(caseUid: string) {
		this.subsciption = this.testCaseBaseService.getLatestTestCaseSteps(caseUid).subscribe(
			st => {
				this.steps = st;
				this.dataSource = new MatTableDataSource<Step>(st);
			},
			error => this.loggerService.logError(error),
		);
	}
}
