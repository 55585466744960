import { TestFileType } from 'src/enums/testFileType';
import { GraphqlFileMetadata } from 'src/models/graphql/graphqlFileMetadata';

/**
 * Represents file metadata.
 */
export class FileMetadata {
	CreateDate: Date;

	FileName: string;

	FileUid: string;

	LinkToTestItemUid: string;

	FileType: TestFileType;

	/**
	 * Constructs file metadata from a graphql response.
	 * @param input Graphql model to construct from.
	 */
	constructor(input: GraphqlFileMetadata) {
		this.CreateDate = new Date(input.CreateDate);
		this.FileName = input.FileName;
		this.FileType = input.FileType;
		this.FileUid = input.FileUid;
		this.LinkToTestItemUid = input.LinkToTestItemUid;
	}
}
