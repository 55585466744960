import { SafeHtml } from '@angular/platform-browser';

import { Classification, ClassificationReason } from 'src/enums/classification';

import { TestResult } from './testResult';
import { Property } from './property';
import { UF } from './uf';
import { ProductProperty } from './productProperty';
import { Version } from './version';
import { OffboardComponent } from './offboardComponent';
import { TestSpecification } from './testSpecification';

export class TestRunViewModel {
	public TestRun: TestRun;

	public TestSuite: TestSuite;

	public TestSpecification: TestSpecification;

	public EcuAndSwIdentifiers?: EcuAndSwIdentifiers[];

	public TestRunType: number;

	public TestSuiteVersion: number; //Test suite version for calling test suite
}

export class TestRun {
	public TestRunUid: string;

	public ExecutionTime: Date;

	public ImportTime: Date;

	public Environment: string;

	public VINNo: string;

	public Configuration: string;

	public SOP: string;

	public TestPeriod: string;

	public Classification: Classification;

	public ClassificationReason: ClassificationReason;

	public ClassificationComment: string;

	public Status: string;

	public RegBy: string;

	public RegTime: Date;

	public TestResults: TestResult[];

	public expaned = false;
}

export interface TestRunAndProperties {
	TestRunUid: string;
	ExecutionTime: Date;
	Properties: Property[];
}

export class TestSuite {
	public TestSuiteUid: string;

	public ItemNo: string;

	public Name: string;

	public expaned = false;

	public Version: number;

	public UFs: UF[];

	public Owners: string[];

	public ProductProperties: ProductProperty[];

	public Versions: Version[];

	public OffboardComponents: OffboardComponent[];

	public ParentTestSuiteUid: string;

	public ParentVersion: string;

	public childrenTestSuites: TestRunViewModel[];

	public Description: string;

	public DescriptionSafeHtml: SafeHtml;

	public RegBy: string;

	public RegTime: Date;

	public ParentUID: string;

	public TestSuites: TestSuite[];
}

export class TestSuiteAlternative {
	public UID: string;

	public Version: number;

	public Name: string;

	public Label: string;

	public FromDate: Date;

	public ToDate: Date;

	public ValidFromSop: string;

	public ValidToSop: string;

	public RegBy: string;

	public RegTime: Date;

	public Properties: Property[];

	public TestGroups: string[];
}

export class TestRunStatistics {
	public InputStatistics: InputStatistic[];
}

export class InputStatistic {
	public Type: string;

	public Count: number;
}

export interface EcuAndSwIdentifiers {
	Name: string;
	SwIdentifiers: string;
}
