<div>
	<!-- date field -->

	<mat-form-field class="mat-form-field">
		<mat-label>From</mat-label>
		<input matInput [matDatepicker]="frompicker" (dateInput)="OnStartDateChange($event.value)" [value]="filterSettings.startDate" />
		<mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
		<mat-datepicker #frompicker></mat-datepicker>
	</mat-form-field>

	<!-- date field -->
	<mat-form-field class="mat-form-field">
		<mat-label>To</mat-label>
		<input matInput [matDatepicker]="topicker" (dateInput)="OnEndDateChange($event.value)" [value]="filterSettings.endDate" />
		<mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
		<mat-datepicker #topicker></mat-datepicker>
	</mat-form-field>

	<!-- TODO Commented out the filters for now, wait for decision if they are to be implemented for Stockholm. -->
	<!-- vehicle field -->
	<!-- @if (environment !== TargetEnvironment.Hero) {
  <mat-form-field class="mat-form-field">
    <mat-label>Environment</mat-label>
    <select matNativeControl [ngModel]="filterSettings.vehicle?.id"
      (change)="UpdateSelectedVehicle($event.target.value)">
      <option *ngFor="let vehicle of vehicles" value={{vehicle.id}}>
        {{vehicle.name}}
      </option>
    </select>
  </mat-form-field>
  } -->
	<!-- organizations field -->
	<!-- @if (environment !== TargetEnvironment.Hero && this.showOrganizationFilter) {
  <mat-form-field class="mat-form-field">
    <mat-label>Organizations</mat-label>
    <mat-select matNativeControl [(ngModel)]="selectedInitialOrgs" [compareWith]="comparer"
      placeholder="Organizations multiple" multiple (selectionChange)="UpdateSelectedOrganizations($event)"
      disableOptionCentering class="multipleSelect">
      <mat-option *ngFor="let group of orgsList" [value]="group">{{group.display}}</mat-option>
    </mat-select>
  </mat-form-field>
  } -->
	<!-- group field -->
	<!-- @if (environment !== TargetEnvironment.Hero) {
  <mat-form-field class="mat-form-field">
    <mat-label>Group</mat-label>
    <select matNativeControl [ngModel]="filterSettings.selectedGroup?.display"
      (change)="UpdateSelectedGroup($event.target.value)">
      <option *ngFor="let group of groupsList" value={{group.value}} [disabled]="group.value === '__'">
        {{group.display}}
      </option>
    </select>
  </mat-form-field>
  } -->

	<!-- @if (environment !== TargetEnvironment.Hero) {
  <div class="ecu-identifier">
    <div>
      <label class="app label">ECU Identifier</label>
    </div>
    <p-treeSelect [(ngModel)]="selectedEcuSWIdentifiers" [options]="ecuSWIdentifiers" [metaKeySelection]="false"
      emptyMessage="Modify the date range to see Identifiers." selectionMode="checkbox"
      (onNodeSelect)="applyEcuFilter()" (onNodeUnselect)="applyEcuFilter()" [pTooltip]="selectedEcuSWIdentifiers"
      placeholder="Select Identifier">
    </p-treeSelect>
  </div>
  } -->
</div>
