import { Component, OnChanges, Input } from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { TmtLoggerService } from 'tmt-logger';

import { TestCaseBaseService } from 'src/services/test-case.base.service';
import { TestCase } from 'src/models/testCase';

@Component({
	selector: 'app-test-case-details',
	templateUrl: './test-case-details.component.html',
	styleUrls: ['./test-case-details.component.scss'],
})
export class TestCaseDetailsComponent implements OnChanges {
	testCase: TestCase;

	selectedVersion: number;

	@Input() testCaseUid: string;

	@Input() testCaseVersion: number;

	@Input() tcversionchangeable: boolean;

	offboardComponentValues: string;

	constructor(
		private testCaseBaseService: TestCaseBaseService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private loggerService: TmtLoggerService,
	) {}

	ngOnChanges() {
		if (this.testCaseVersion !== undefined) {
			this.getDetails(this.testCaseUid, this.testCaseVersion);
		} else {
			this.getLatestDetails(this.testCaseUid);
		}
	}

	private getDetails(testCaseUid: string, testCaseVersion: number) {
		this.testCaseBaseService.getTestCaseDetails(testCaseUid, testCaseVersion).subscribe(
			tc => {
				this.testCase = tc;
				this.testCase.DescriptionSafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.testCase.Description);
				this.offboardComponentValues = this.testCase.OffboardComponents.map(o => o.Value).join(', ');
				this.selectedVersion = tc.Version;
			},
			error => this.loggerService.logError(error),
		);
	}

	private getLatestDetails(testCaseUid: string) {
		this.testCaseBaseService.getLatestTestCaseDetails(testCaseUid).subscribe(
			tc => {
				this.testCase = tc;
				this.testCase.DescriptionSafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.testCase.Description);
				this.offboardComponentValues = this.testCase.OffboardComponents.map(o => o.Value).join(', ');
				this.selectedVersion = tc.Version;
			},
			error => this.loggerService.logError(error),
		);
	}

	public getVersion(versionNo: number) {
		if (!versionNo) {
			this.getLatestDetails(this.testCaseUid);

			const queryParams: Params = { uid: this.testCaseUid };
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams: queryParams,
				queryParamsHandling: 'merge', // remove to replace all query params by provided
			});
		} else {
			this.getDetails(this.testCaseUid, versionNo);

			const queryParams: Params = { uid: this.testCaseUid, version: versionNo };
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams: queryParams,
				queryParamsHandling: 'merge', // remove to replace all query params by provided
			});
		}
	}
}
