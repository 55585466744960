/**
 * Defines possible file types for test files.
 */
export enum TestFileType {
	NotSpecified = 'NotSpecified',
	TestCaseAttachment = 'TestCaseAttachment',
	E2 = 'E2',
	Xcom = 'Xcom',
	Ats = 'Ats',
	OffboardAgent = 'OffboardAgent',
	OffboardGadget = 'OffboardGadget',
	Result = 'Result',
}
