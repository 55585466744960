<div class="row">
	<span *ngIf="!this.resultExist">
		<span class="scania-icon-info xs"></span>
		<span class="bold">You are not authorized to view this test result</span>
	</span>
	<div class="col-md-12" *ngIf="this.resultExist">
		<div id="divTestResult" class="col-md-9">
			@if (environment !== TargetEnvironment.Hero) {
				<div class="col-md-12 no-padding">
					<div class="col-md-4 no-padding">
						<a href="{{ reportsurl }}reports?testRunUid={{ res?.TestRunUid }}" target="_blank">
							<u>
								<h5 class="scania-link no-margin" title="Link to Fenix Test Reports Web site">
									Create report for this test run
									<span class="scania-icon-report sm" style="padding-bottom: 40px"></span>
								</h5>
							</u>
						</a>
					</div>
				</div>
			}
			<div style="text-align: right">
				<span [routerLink]="['/testcase/', res?.TestCaseUid]" class="scania-link small-font" style="align-items: right">[View all results for this test case]</span>
			</div>
			<div id="divTestRunHeading" class="col-md-12 no-padding">
				<div class="results-heading">
					<h2>TEST RESULT: {{ res?.TestCaseItemNo }} - {{ res?.TestCaseName }}</h2>
				</div>
				<div class="results-heading-details">
					&nbsp;(version {{ res?.TestCaseVersion }}) - {{ res?.ExecutionTimeTestResult | date: 'yyyy-MM-dd HH:mm:ss' }} - {{ res?.VehicleName | uppercase }} -
					{{ res?.ExecutedBy | uppercase }}
				</div>
			</div>
			<table mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="Result">
					<th mat-header-cell *matHeaderCellDef>Result</th>
					<td
						mat-cell
						*matCellDef="let element"
						[ngClass]="{
							failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
							failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
						}"
					>
						{{ element.Result }}
					</td>
				</ng-container>
				<ng-container matColumnDef="TroubleReports">
					<th mat-header-cell *matHeaderCellDef>
						Trouble reports
						@if (environment !== TargetEnvironment.Hero) {
							<a href="https://help.tms.scania.com/#Create%20Jira%20issue%20and%20link%20it%20to%20a%20Test%20Case" target="_blank">
								<i class="scania-icon-help-white xs" title="Click to open Help"></i>
							</a>
						}
					</th>
					<td
						mat-cell
						*matCellDef="let element"
						[ngClass]="{
							failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
							failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
						}"
					>
						<i *ngIf="element.troubleReports.length > 0" class="scania-icon-trouble-report xs" title="Trouble reports exist"></i>
					</td>
				</ng-container>
				<ng-container matColumnDef="ResultComment">
					<th mat-header-cell *matHeaderCellDef width="40%">Result comment</th>
					<td
						mat-cell
						*matCellDef="let element"
						[ngClass]="{
							failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
							failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
						}"
					>
						<span *ngIf="element.ResultComment && element.ResultText; else elseBlockResultComment">
							<button
								*ngIf="element.ResultComment && element.ResultText"
								mat-button
								(click)="$event.stopPropagation(); openDialog(element.ResultComment, element.ResultText)"
								class="icon-button"
								title="View result text"
							>
								<div class="icon-button-text">{{ element.ResultComment }}</div>
								<div class="icon-button-icon"><mat-icon>more_horiz</mat-icon></div>
							</button>
						</span>
						<ng-template #elseBlockResultComment>
							<span *ngIf="element.ResultComment">
								{{ element.ResultComment }}
							</span>
						</ng-template>
						<div *ngIf="(res?.PositiveComment !== '' && res?.PositiveComment !== null) || (res?.NegativeComment !== '' && res?.NegativeComment !== null)">
							<table class="table-condensed no-margin">
								<tbody>
									<tr *ngIf="res?.PositiveComment !== '' && res?.PositiveComment !== null">
										<td class="no-padding" title="Positive comment">
											<span class="glyphicon glyphicon-ok-sign text-success"></span>
											{{ res?.PositiveComment }}
										</td>
									</tr>
									<tr *ngIf="res?.NegativeComment !== '' && res?.NegativeComment !== null">
										<td class="no-padding" title="Negative comment">
											<span class="glyphicon glyphicon-remove-sign text-danger"></span>
											{{ res?.NegativeComment }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</td>
				</ng-container>
				<!-- <ng-container matColumnDef="Classification">
        <th mat-header-cell *matHeaderCellDef>Classification</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'failedreasonNANPBySystem':element.FailedReason !== 0 && element.FailedBy === 'System',
                'failedreasonNANPByUser':element.FailedReason !== 0 && element.FailedBy !== 'System'}">{{element.Classification}}
          <button *ngIf="element.Classification" mat-button (click)="openHistoryDialog(res?.TestResultUid)" class="icon-button" title="History"><mat-icon>history</mat-icon></button>
        </td>
      </ng-container>
      <ng-container matColumnDef="ClassificationComment">
        <th mat-header-cell *matHeaderCellDef>Classification Comment</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'failedreasonNANPBySystem':element.FailedReason !== 0 && element.FailedBy === 'System',
                'failedreasonNANPByUser':element.FailedReason !== 0 && element.FailedBy !== 'System'}">{{element.ClassificationComment}}</td>
      </ng-container> -->
				<ng-container matColumnDef="FailedReasonText">
					<th mat-header-cell *matHeaderCellDef>Not executed reason</th>
					<td
						mat-cell
						*matCellDef="let element"
						[ngClass]="{
							failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
							failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
						}"
					>
						{{ element.FailedReason === 0 ? '' : element.FailedReasonText }}
					</td>
				</ng-container>
				<ng-container matColumnDef="FailedBy">
					<th mat-header-cell *matHeaderCellDef width="10%">Not executed decided by</th>
					<td
						mat-cell
						*matCellDef="let element"
						[ngClass]="{
							failedreasonNANPBySystem: element.FailedReason !== 0 && element.FailedBy === 'System',
							failedreasonNANPByUser: element.FailedReason !== 0 && element.FailedBy !== 'System',
						}"
					>
						{{ element.FailedBy }}
					</td>
				</ng-container>
				<ng-container matColumnDef="FailedReasonComment">
					<th mat-header-cell *matHeaderCellDef>Not executed comment</th>
					<td mat-cell *matCellDef="let element">
						<span *ngIf="element.FailedReasonComment && element.ErrorText; else elseBlockFailedReasonComment">
							<button
								*ngIf="element.FailedReasonComment && element.ErrorText"
								mat-button
								(click)="$event.stopPropagation(); openDialog(element.FailedReasonComment, element.ErrorText)"
								class="icon-button"
								title="View Not executed text"
							>
								<span class="icon-button-text">{{ element.FailedReasonComment }}</span>
								<span class="icon-button-icon"><mat-icon>more_horiz</mat-icon></span>
							</button>
						</span>
						<ng-template #elseBlockFailedReasonComment>
							<span *ngIf="element.FailedReasonComment">
								{{ element.FailedReasonComment }}
							</span>
						</ng-template>
					</td>
				</ng-container>
				@if (environment !== TargetEnvironment.Hero) {
					<ng-container matColumnDef="TestCaseAlternativeFailedReasons">
						<th mat-header-cell *matHeaderCellDef>Test case alternative: failed reason</th>
						<td mat-cell *matCellDef="let element">
							<table *ngIf="tcAlternativeFailedReasonsExist">
								<tr *ngFor="let failedReason of res?.testCaseAlternativeFailedReasons">
									<td class="no-padding">
										<span title="Derived from test case alternative: {{ failedReason.testCaseAlternativeName }} &#xA;with UID: {{ failedReason.testCaseAlternativeUID }}">
											{{ failedReason.testCaseAlternativeName }}: {{ failedReason.failedComment }}
											<span class="scania-icon-help xs" style="padding-bottom: 20px"></span>
										</span>
									</td>
								</tr>
							</table>
						</td>
					</ng-container>
				}
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ failed: row.Result === 'failed', passed: row.Result === 'passed' }"></tr>
			</table>

			<mat-expansion-panel [expanded]="true" class="panel-margin" *ngIf="objectUnderTestExist">
				<mat-expansion-panel-header>
					<mat-panel-title>Objects under test</mat-panel-title>
				</mat-expansion-panel-header>
				<div id="divObjectsUnderTest" *ngIf="res?.TestResultUid">
					<div *ngIf="res?.ecus.length > 0">
						<table class="table">
							<tbody>
								<tr *ngFor="let ecu of res?.ecus">
									<td width="20%">ECU</td>
									<td *ngIf="ecusInReadout?.includes(ecu.name)" class="bold no-padding" width="10%">{{ ecu.name }}</td>
									<td *ngIf="!ecusInReadout?.includes(ecu.name)" class="bold no-padding" width="10%" title="This ECU is not included in ECU identification readout, see panel below">
										{{ ecu.name }}
										<span class="scania-icon-warning xs"></span>
									</td>
									<td class="no-padding">{{ ecu.description }}</td>
									<td class="no-padding">{{ ecu.fpcCondition }}</td>
									<td class="no-padding">ECUFamily: {{ ecu.familyName }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div *ngIf="res?.offboardComponents.length > 0">
						<table class="table">
							<tbody>
								<tr *ngFor="let offboardComponent of res?.offboardComponents">
									<td width="20%">Offboard component</td>
									<td class="bold no-padding">{{ offboardComponent.Value }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</mat-expansion-panel>

			<div id="divImages" *ngIf="files$ | async as files">
				<app-images [linkUid]="res?.LinkUid"></app-images>
			</div>

			<mat-expansion-panel [expanded]="true" class="panel-margin" *ngIf="troubleReportsExist">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<span class="scania-icon-trouble-report xs"></span>
						Trouble reports
						<span class="badge badge-default">{{ this.totalTroubleReports }}</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div id="divTroubleReports" *ngIf="res?.TestResultUid">
					<app-trouble-reports [resultUid]="res?.TestResultUid" (countTroubleReports)="showTroubleReportCounts($event)"></app-trouble-reports>
				</div>
			</mat-expansion-panel>

			<div id="divSteps" *ngIf="res?.TestCaseUid && res?.InputType === inputTypePassFail">
				<app-test-case-steps [caseUid]="res?.TestCaseUid" [caseVersion]="res?.TestCaseVersion"></app-test-case-steps>
			</div>
			<br />

			<div>
				<button mat-button (click)="accordion.openAll()" class="btn btn-sm btn-primary">Expand all panels</button>
				<button mat-button (click)="accordion.closeAll()" class="btn btn-sm btn-default">Collapse all panels</button>
			</div>
			<mat-accordion multi>
				<div class="bottom-margin-small">
					<mat-expansion-panel [expanded]="true" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>Test case details</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divTestCaseDetails" *ngIf="res?.TestCaseUid">
							<app-test-case-details [testCaseUid]="res?.TestCaseUid" [testCaseVersion]="res?.TestCaseVersion" [tcversionchangeable]="false"></app-test-case-details>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small" *ngIf="res?.TestCaseUid">
					<mat-expansion-panel [expanded]="false" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Test case alternatives
								<span class="badge badge-default">{{ this.totalTestCaseAlternatives }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divAlternativesForTestCase">
							<app-test-case-alternative
								[testCaseUid]="res?.TestCaseUid"
								[testCaseVersion]="res?.TestCaseVersion"
								(countTestCaseAlternatives)="showTestCaseAlternatives($event)"
							></app-test-case-alternative>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small" *ngIf="res?.TestCaseUid">
					<mat-expansion-panel [expanded]="false" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Connected requirements to this test result for {{ res?.TestCaseItemNo }} - {{ res?.TestCaseName }} (version {{ res?.TestCaseVersion }}) executed on
								{{ res?.ExecutionTimeTestResult ? (res?.ExecutionTimeTestResult | date: 'yyyy-MM-dd HH:mm:ss') : (res?.ExecutionTimeTestRun | date: 'yyyy-MM-dd HH:mm:ss') }}
								<span class="badge badge-default">{{ this.totalRequirements }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divRequirementsForTestCase">
							<app-requirements
								[testCaseName]="res?.TestCaseName"
								[testCaseUid]="res?.TestCaseUid"
								[testCaseVersion]="res?.TestCaseVersion"
								[testResultUid]="res?.TestResultUid"
								(countRequirements)="showRequirements($event)"
							></app-requirements>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								ECU identification readout
								<span class="badge badge-default">{{ this.totalEcuReadouts }}</span>
								<span *ngIf="this.originEcuReadout" class="small-font-italic">{{ this.originEcuReadout }}</span>
								<span *ngIf="this.fileTimeEcuReadout" class="small-font-italic">File creation time: {{ this.fileTimeEcuReadout | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divEcuReadouts" *ngIf="res?.TestRunUid">
							<app-ecu-readout (countEcuReadouts)="showEcuReadoutCounts($event)"></app-ecu-readout>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								E2 parameters
								<span class="badge badge-default">{{ this.totalEcuParams }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divEcuParams" *ngIf="res?.TestRunUid">
							<app-ecu-param (countEcuParams)="showEcuParamCounts($event)"></app-ecu-param>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Properties
								<span class="badge badge-default">{{ this.totalProperties }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divProperties" *ngIf="res?.TestRunUid">
							<div>
								<button mat-button (click)="propertiesaccordion.openAll()" class="btn btn-sm btn-primary">Expand all Properties panels</button>
								<button mat-button (click)="propertiesaccordion.closeAll()" class="btn btn-sm btn-default">Collapse all Properties panels</button>
							</div>
							<mat-accordion #propertiesaccordion="matAccordion" multi>
								<mat-expansion-panel expanded="false" class="bottom-margin">
									<mat-expansion-panel-header>
										<mat-panel-title>
											Properties for test result
											<span class="badge badge-default">{{ this.totalResultProperties }}</span>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div *ngIf="res?.TestResultUid">
										<app-test-result-properties [resultUid]="res?.TestResultUid" (countResultProperties)="showResultPropertiesCount($event)"></app-test-result-properties>
									</div>
								</mat-expansion-panel>
								<mat-expansion-panel expanded="false" class="bottom-margin">
									<mat-expansion-panel-header>
										<mat-panel-title>
											Properties for test run
											<span class="badge badge-default">{{ this.totalRunProperties }}</span>
										</mat-panel-title>
									</mat-expansion-panel-header>
									<div *ngIf="res?.TestRunUid">
										<app-test-run-properties [runUid]="res?.TestRunUid" (countRunProperties)="showRunPropertiesCount($event)"></app-test-run-properties>
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								SOPS
								<span class="badge badge-default">{{ this.totalSOPS }}</span>
								<span *ngIf="this.originSOPS" class="small-font-italic">{{ this.originSOPS }}</span>
								<span *ngIf="this.fileTimeSOPS" class="small-font-italic">File creation time: {{ this.fileTimeSOPS | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
								<span *ngIf="this.versionSOPS" class="small-font-italic">File version: {{ this.versionSOPS }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divSOPS" *ngIf="res?.TestRunUid">
							<app-sops
								[runUid]="res?.TestRunUid"
								(countTotalSOPS)="showSOPSCounts($event)"
								(sopsOrigin)="showSOPSOrigin($event)"
								(sopsFileTime)="showSOPSFileTime($event)"
								(sopsVersion)="showSOPSVersion($event)"
								(sopsFPCs)="showSOPSFPCs($event)"
							></app-sops>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>
								Offboard components
								<span class="badge badge-default">{{ this.totalOffboardComponents }}</span>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divDataComponents" *ngIf="res?.TestRunUid">
							<app-offboard-component [runUid]="res?.TestRunUid" (countOffboardComponents)="showOffboardComponentsCount($event)"></app-offboard-component>
						</div>
					</mat-expansion-panel>
				</div>
				@if (environment !== TargetEnvironment.Hero) {
					@if (files$ | async; as files) {
						<div class="bottom-margin-small">
							<mat-expansion-panel class="bottom-margin">
								<mat-expansion-panel-header>
									<mat-panel-title>
										Attachments
										<span class="badge badge-default">{{ files.length }}</span>
									</mat-panel-title>
								</mat-expansion-panel-header>
								<div id="divAttachments" *ngIf="res?.TestResultUid">
									@if (files.length) {
										<app-attachments [files]="files"></app-attachments>
									}
								</div>
							</mat-expansion-panel>
						</div>
					}
				}
			</mat-accordion>
			<br />
			<br />
		</div>

		<div id="TestResultAdditionalData" class="col-md-3">
			<div id="Environment" class="col-md-12 bottom-margin-small">
				<mat-expansion-panel [expanded]="true">
					<mat-expansion-panel-header>
						<mat-panel-title>Environment for test run</mat-panel-title>
					</mat-expansion-panel-header>
					@if (res?.VehicleName === 'No_Vehicle' || environment === TargetEnvironment.Hero) {
						<span>
							<h4>{{ res?.VehicleName }}</h4>
						</span>
					} @else if (res?.VehicleName !== 'No_Vehicle') {
						<span>
							<a href="https://testvehicle.scania.com/#/view;testCarrier={{ res?.VehicleName }}" target="_blank">
								<u>
									<h4
										class="scania-link"
										title="Link to TESTiT Test Vehicles site for this environment. Requires access which can be ordered from OrderIT. Not all environments are represented in TESTiT. "
									>
										{{ res?.VehicleName }}
									</h4>
								</u>
							</a>
						</span>
					}
					<div id="divEnvironment" *ngIf="FPCsSOPS?.length > 0">
						<app-environment [VINNo]="res?.VINNo" [FPCs]="FPCsSOPS"></app-environment>
					</div>
				</mat-expansion-panel>
			</div>
			<div id="divTestResultDetails" class="col-md-12">
				<mat-expansion-panel [expanded]="true">
					<mat-expansion-panel-header>
						<mat-panel-title>Test result details</mat-panel-title>
					</mat-expansion-panel-header>
					<table id="tableTestResultDetails" class="table-condensed" width="100%">
						<tbody width="100%">
							<tr title="If the result is going to be used (Valid) or not (Not valid)">
								<td width="40%">
									Classification
									<span class="scania-icon-help xs" style="padding-bottom: 20px"></span>
								</td>
								<td width="5px;"></td>
								<td *ngIf="classificationDescription === 'Valid'">{{ classificationDescription }}</td>
								<td *ngIf="classificationDescription === 'NotValid'">
									<span style="color: red">{{ classificationDescription }}</span>
								</td>
							</tr>
							<tr *ngIf="classificationReasonDescription !== 'OK'" title="What causes the test result to not be valid">
								<td>
									Classification reason
									<span class="scania-icon-help xs" style="padding-bottom: 20px"></span>
								</td>
								<td width="5px;"></td>
								<td>{{ classificationReasonDescription }}</td>
							</tr>
							<tr *ngIf="res?.ClassificationComment?.length > 0">
								<td>Classification comment</td>
								<td width="5px;"></td>
								<td>{{ res?.ClassificationComment }}</td>
							</tr>
							<tr>
								<td>Test result UID</td>
								<td width="5px;"></td>
								<td>{{ res?.TestResultUid }}</td>
							</tr>
							<tr>
								<td>Test run UID</td>
								<td width="5px;"></td>
								<td>{{ res?.TestRunUid }}</td>
							</tr>
							<tr *ngIf="res?.TestScriptName">
								<td>Test script name</td>
								<td width="5px;"></td>
								<td>{{ res?.TestScriptName }}</td>
							</tr>
							<tr *ngIf="res?.TestScriptVersion">
								<td>Test script version</td>
								<td width="5px;"></td>
								<td>{{ res?.TestScriptVersion }}</td>
							</tr>
							<!-- <tr>
                <td>UC</td>
                <td width="5px;"></td>
                <td>{{res?.UC}}</td>
            </tr>
            <tr>
                <td>UF</td>
                <td width="5px;"></td>
                <td>{{res?.UF}}</td>
            </tr>
            <tr>
                <td>SCN</td>
                <td width="5px;"></td>
                <td>{{res?.SCN}}</td>
            </tr> -->
							<tr>
								<td>Test run execution time</td>
								<td width="5px;"></td>
								<td>{{ res?.ExecutionTimeTestRun | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
							</tr>
							<tr>
								<td>Executed by</td>
								<td width="5px;"></td>
								<td style="word-wrap: break-word">{{ res?.ExecutedBy }}</td>
							</tr>
							<!-- <tr>
                <td>Execution location</td>
                <td width="5px;"></td>
                <td>{{res?.ExecutionLocation}}</td>
            </tr>     -->
							<tr
								class="top-border scania-link"
								[routerLink]="['/testcasedetails']"
								[queryParams]="{ uid: res?.TestCaseUid, version: res?.TestCaseVersion }"
								title="Show test case details page"
							>
								<td class="scania-link"><u>Test case ItemNo</u></td>
								<td class="scania-link" width="5px;"></td>
								<td class="bold scania-link">
									<u>{{ res?.TestCaseItemNo }}</u>
								</td>
							</tr>
							<tr class="scania-link" [routerLink]="['/testcasedetails']" [queryParams]="{ uid: res?.TestCaseUid, version: res?.TestCaseVersion }" title="Show test case details page">
								<td class="scania-link"><u>Test case</u></td>
								<td class="scania-link" width="5px;"></td>
								<td class="bold scania-link">
									<u>{{ res?.TestCaseName }}</u>
								</td>
							</tr>
							<tr class="scania-link" [routerLink]="['/testcasedetails']" [queryParams]="{ uid: res?.TestCaseUid, version: res?.TestCaseVersion }" title="Show test case details page">
								<td class="scania-link"><u>Test case UID</u></td>
								<td class="scania-link" width="5px;"></td>
								<td class="bold scania-link">
									<u>{{ res?.TestCaseUid }}</u>
								</td>
							</tr>
							<tr>
								<td>Test case version</td>
								<td width="5px;"></td>
								<td>{{ res?.TestCaseVersion }}</td>
							</tr>
						</tbody>
					</table>
					<table class="table-condensed" width="100%">
						<tbody width="100%">
							<span *ngIf="res?.TestRunType === 410; else elseBlockResultDetails">
								<tr
									class="scania-link"
									[routerLink]="['/testspecificationdetails']"
									[queryParams]="{ uid: res?.TestSpecificationUid, version: res?.TestSpecificationVersion }"
									title="Show test specification details page"
								>
									<td class="scania-link" width="40%">Test specification ItemNo</td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">{{ res?.TestSpecificationItemNo }}</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testspecificationdetails']"
									[queryParams]="{ uid: res?.TestSpecificationUid, version: res?.TestSpecificationVersion }"
									title="Show test specification details page"
								>
									<td class="scania-link" width="40%">Test specification</td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">{{ res?.TestSpecificationName }}</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testspecificationdetails']"
									[queryParams]="{ uid: res?.TestSpecificationUid, version: res?.TestSpecificationVersion }"
									title="Show test specification details page"
								>
									<td class="scania-link">Test specification UID</td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.TestSpecificationUid }}</u>
									</td>
								</tr>
								<tr>
									<td>Test specification version</td>
									<td width="5px;"></td>
									<td>{{ res?.TestSpecificationVersion }}</td>
								</tr>
							</span>
							<ng-template #elseBlockResultDetails>
								<tr
									class="top-border scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: res?.TestSuiteUid, version: res?.TestSuiteVersion }"
									title="Show test suite details page"
								>
									<td class="scania-link" width="40%"><u>Test suite ItemNo</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.TestSuiteItemNo }}</u>
									</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: res?.TestSuiteUid, version: res?.TestSuiteVersion }"
									title="Show test suite details page"
								>
									<td class="scania-link" width="40%"><u>Test suite</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.TestSuiteName }}</u>
									</td>
								</tr>
								<tr
									class="scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: res?.TestSuiteUid, version: res?.TestSuiteVersion }"
									title="Show test suite details page"
								>
									<td class="scania-link"><u>Test suite UID</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.TestSuiteUid }}</u>
									</td>
								</tr>
								<tr>
									<td>Test suite version</td>
									<td width="5px;"></td>
									<td>{{ res?.TestSuiteVersion }}</td>
								</tr>
								<tr
									*ngIf="res?.MainTestSuiteName"
									class="top-border scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: res?.MainTestSuiteUid, version: res?.MainTestSuiteVersion }"
									title="Show main test suite details"
								>
									<td class="scania-link"><u>Main test suite ItemNo</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.MainTestSuiteItemNo }}</u>
									</td>
								</tr>
								<tr
									*ngIf="res?.MainTestSuiteName"
									class="scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: res?.MainTestSuiteUid, version: res?.MainTestSuiteVersion }"
									title="Show main test suite details"
								>
									<td class="scania-link"><u>Main test suite</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.MainTestSuiteName }}</u>
									</td>
								</tr>
								<tr
									*ngIf="res?.MainTestSuiteName"
									class="scania-link"
									[routerLink]="['/testsuitedetails']"
									[queryParams]="{ uid: res?.MainTestSuiteUid, version: res?.MainTestSuiteVersion }"
									title="Show main test suite details"
								>
									<td class="scania-link"><u>Main test suite UID</u></td>
									<td class="scania-link" width="5px;"></td>
									<td class="bold scania-link">
										<u>{{ res?.MainTestSuiteUid }}</u>
									</td>
								</tr>
								<tr *ngIf="res?.MainTestSuiteVersion">
									<td>Main test suite version</td>
									<td width="5px;"></td>
									<td>{{ res?.MainTestSuiteVersion }}</td>
								</tr>
							</ng-template>
						</tbody>
					</table>
				</mat-expansion-panel>
			</div>
		</div>
	</div>
</div>
