import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { Subscription } from 'rxjs';

import { TmtLoggerService } from 'tmt-logger';

import { GetTestRunAction } from 'src/services/Actions/TestRunAction';
import { TestRunViewModel } from 'src/models/testRun';
import { TestRunService } from 'src/services/test-run.service';

interface TreeNode {
	id: string;
	itemno: string;
	name: string;
	children?: TreeNode[];
	className: string;
	url?: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
	expandable: boolean;
	itemno: string;
	name: string;
	level: number;
	className: string;
	url: string;
}
@Component({
	selector: 'app-tree-menu',
	templateUrl: './tree-menu.component.html',
	styleUrls: ['./tree-menu.component.scss'],
})
export class TreeMenuComponent implements OnInit {
	testRunViewModel: TestRunViewModel;

	subscription: Subscription;

	private _transformer = (node: TreeNode, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			itemno: node.itemno,
			name: node.name,
			level: level,
			className: node.className,
			url: node.url,
		};
	};

	activeNode: TreeNode;

	treeControl = new FlatTreeControl<ExampleFlatNode>(
		node => node.level,
		node => node.expandable,
	);

	treeFlattener = new MatTreeFlattener(
		this._transformer,
		node => node.level,
		node => node.expandable,
		node => node.children,
	);

	treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	readonly inconclusiveIcon: string = 'glyphicon glyphicon-question-sign text-warning';

	readonly successIcon: string = 'glyphicon glyphicon-ok-sign text-success';

	readonly failureIcon: string = 'glyphicon glyphicon-remove-sign text-danger';

	readonly missingIcon: string = 'glyphicon glyphicon-minus';

	constructor(
		private testRunService: TestRunService,
		private testRunActionService: GetTestRunAction,
		private loggerService: TmtLoggerService,
		private router: Router,
	) {}

	hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

	@ViewChild('tree') tree;

	ngOnInit(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		//needed to refresh page so menu items does not render at every click
		if (!localStorage.getItem('treeMenuReload')) {
			localStorage.setItem('treeMenuReload', 'no reload');
			location.reload();
		} else {
			localStorage.removeItem('treeMenuReload');
		}

		this.testRunService.selectedRunUid.subscribe(rid => this.testRunActionService.getTestRun(rid));
		this.testRunActionService.testrunData.subscribe(
			tr => {
				this.testRunViewModel = tr;
				this.buildTree(tr);
			},
			error => this.loggerService.logError(error),
		);
	}

	private buildTree(runWithSuite: TestRunViewModel) {
		const TSTreeNodes: TreeNode[] = [];
		if (runWithSuite === undefined) {
			return;
		}
		if (runWithSuite?.TestRun?.TestResults?.length > 0) {
			const key = 'TestSuiteUid';
			const uniqueTS = [...new Map(runWithSuite?.TestRun?.TestResults.map(item => [item[key], item])).values()];

			uniqueTS.forEach(ts => {
				if (runWithSuite?.TestRunType === 410) {
					const TSTreeNode: TreeNode = { id: ts.TestSpecificationUid, itemno: ts.TestSpecificationItemNo, name: ts.TestSpecificationName, className: '', children: [] };
					TSTreeNodes.push(TSTreeNode);
				} else {
					const TSTreeNode: TreeNode = { id: ts.TestSuiteUid, itemno: ts.TestSuiteItemNo, name: ts.TestSuiteName, className: '', children: [] };
					TSTreeNodes.push(TSTreeNode);
				}
			});

			runWithSuite.TestRun.TestResults.forEach(tr => {
				const result: string = tr.Result;
				const treeNodeTestCase: TreeNode = {
					id: tr.TestCaseUid,
					itemno: tr.TestCaseItemNo,
					name: tr.TestCaseName,
					className: this.getIconBasedOnResult(result),
					url: `/#/testresult/${tr.TestResultUid}`,
				};
				if (runWithSuite?.TestRunType === 410) {
					const foundTestSpecification = TSTreeNodes.filter(x => x.id === tr.TestSpecificationUid);
					foundTestSpecification[0].children.push(treeNodeTestCase);
				} else {
					const foundTS = TSTreeNodes.filter(x => x.id === tr.TestSuiteUid);
					foundTS[0].children.push(treeNodeTestCase);
				}
			});
		}
		this.treeDataSource.data = TSTreeNodes;
		this.tree.treeControl.nodes = TSTreeNodes;
		this.tree.treeControl.expandAll();

		// this.tree.treeControl.nodes.forEach(tsNode => {
		//   tsNode.children.forEach(tcNode => {
		//     if (tcNode.url === this.activeNode?.url) {
		//       console.log("TCNode: ", tcNode);
		//     }
		//   });
		// });
		// console.log("Active node: ", this.activeNode);
	}

	public setActiveNode(nod: TreeNode) {
		this.activeNode = nod;
	}

	private getIconBasedOnResult(resultsForNode: string): string {
		if (resultsForNode === 'passed') {
			return this.successIcon;
		} else if (resultsForNode === 'failed') {
			return this.failureIcon;
		} else {
			return this.missingIcon;
		}
	}
}
