import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TmtLoggerService } from 'tmt-logger';

import { TestRunViewModel } from 'src/models/testRun';
import { TestSpecification } from 'src/models/testSpecification';
import { TestRunBaseService } from 'src/services/test-run.base.service';
import { TestSpecificationBaseService } from 'src/services/test-specification.base.service';

@Component({
	selector: 'app-test-specifications',
	templateUrl: './test-specifications.component.html',
	styleUrls: ['./test-specifications.component.scss'],
})
export class TestSpecificationsComponent implements OnInit, AfterViewInit, OnDestroy {
	private subscription: Subscription;

	displayedColumns: string[] = ['TestRun.TestRunUid', 'TestRun.Environment', 'TestSpecification.Version', 'TestRun.ExecutionTime', 'TestRun.RegBy'];

	dataSource: MatTableDataSource<TestRunViewModel>;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	@ViewChild(MatSort) sort: MatSort;

	maxall = 10;

	testSpecifications: TestRunViewModel[];

	activeTestSpecification: TestSpecification;

	gettingTestSpecifications: boolean = false;

	constructor(
		private testRunBaseService: TestRunBaseService,
		private testSpecificationBaseService: TestSpecificationBaseService,
		private route: ActivatedRoute,
		private router: Router,
		private loggerService: TmtLoggerService,
	) {}

	ngOnInit(): void {
		this.gettingTestSpecifications = false;
		this.route.url.pipe(switchMap(segment => this.testSpecificationBaseService.getLatestTestSpecification(segment[1].path))).subscribe(ts => (this.activeTestSpecification = ts));
		this.getTestSpecifications();
	}

	// sorting and paging does not work unless the fetch of test suites also is done in AfterViewInit
	ngAfterViewInit(): void {
		this.route.url.pipe(switchMap(segment => this.testSpecificationBaseService.getLatestTestSpecification(segment[1].path))).subscribe(ts => (this.activeTestSpecification = ts));
		this.getTestSpecifications();
	}

	private getTestSpecifications(): void {
		this.gettingTestSpecifications = true;
		this.subscription = this.route.url.pipe(switchMap(urlSegment => this.testRunBaseService.getTestRunsByTestSpecificationUid(urlSegment[1].path))).subscribe(
			testspecificationdata => {
				this.testSpecifications = testspecificationdata;
				const dataSource = new MatTableDataSource<TestRunViewModel>(this.testSpecifications);
				dataSource.paginator = this.paginator;
				// set a new sortingDataAccessor to be able to sort nested objects
				dataSource.sortingDataAccessor = (item, property) => {
					switch (property) {
						case 'TestRun.TestRunUid':
							return item.TestRun.TestRunUid;
						case 'TestRun.Environment':
							return item.TestRun.Environment;
						case 'TestSpecification.Version':
							return item.TestSpecification.Version;
						case 'TestRun.ExecutionTime':
							return item.TestRun.ExecutionTime;
						case 'TestRun.RegBy':
							return item.TestRun.RegBy;
						default:
							return item[property];
					}
				};
				dataSource.sort = this.sort;
				// set a new filterPredicate function to be able to filter nested objects
				dataSource.filterPredicate = (data, filter: string) => {
					const accumulator = (currentTerm, key) => {
						return this.nestedFilterCheck(currentTerm, data, key);
					};
					const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
					const transformedFilter = filter.trim().toLowerCase();
					return dataStr.indexOf(transformedFilter) !== -1;
				};
				this.dataSource = dataSource;
				this.gettingTestSpecifications = false;
			},
			error => {
				this.loggerService.logError(error), (this.gettingTestSpecifications = false);
			},
		);
	}

	private nestedFilterCheck(search, data, key) {
		if (typeof data[key] === 'object') {
			for (const k in data[key]) {
				if (data[key][k] !== null) {
					search = this.nestedFilterCheck(search, data[key], k);
				}
			}
		} else {
			search += data[key];
		}
		return search;
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	public getPageSizeOptions(): number[] {
		if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
			return [10, 20, this.dataSource.paginator.length];
		} else {
			return [10, 20];
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public navigateTo(row: any) {
		this.router.navigate(['/testresult/testrun/' + row.TestRun.TestRunUid]);
	}
}
