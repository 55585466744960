import { Component, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { TmtLoggerService } from 'tmt-logger';

import { Property } from 'src/models/property';
import { TestResultsBaseService } from 'src/services/test-results.base.service';

@Component({
	selector: 'app-test-result-properties',
	templateUrl: './test-result-properties.component.html',
	styleUrls: ['./test-result-properties.component.scss'],
})
export class TestResultPropertiesComponent implements OnChanges {
	constructor(
		private testResultsBaseService: TestResultsBaseService,
		private loggerService: TmtLoggerService,
	) {}

	properties: Property[];

	displayedColumns: string[] = ['Name', 'Value', 'Description'];

	dataSource;

	maxall = 10;

	@ViewChild(MatPaginator) paginator: MatPaginator;

	@ViewChild(MatSort) sort: MatSort;

	@Input() resultUid: string;

	@Output() countResultProperties: EventEmitter<any> = new EventEmitter();

	ngOnChanges() {
		this.getProperties(this.resultUid);
	}

	private getProperties(resultUid: string) {
		this.testResultsBaseService.getTestResultProperties(resultUid).subscribe(
			data => {
				this.properties = data;
				this.countResultProperties.emit(this.properties.length);
				this.dataSource = new MatTableDataSource<Property>(data);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;
			},
			error => this.loggerService.logError(error),
		);
	}

	public getPageSizeOptions(): number[] {
		if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
			return [10, 20, this.dataSource.paginator.length];
		} else {
			return [10, 20];
		}
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
