import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';

import { TreeSelectModule } from 'primeng/treeselect';

import { AppRoutingModule } from 'src/app/app-routing.module';

import { FilterComponent } from './components/filter/filter.component';
import { StartPageComponent } from './start-page.component';
import { StartPageTableComponent } from './components/start-page-table/start-page-table.component';

// user our own format for date's. We will display it as dateInput shows.
export const CUSTOM_FORMAT: MatDateFormats = {
	parse: {
		dateInput: 'MM-DD-YYYY',
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [StartPageTableComponent, FilterComponent, StartPageComponent],
	imports: [
		CommonModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		FormsModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatMomentDateModule,
		MatFormFieldModule,
		MatSelectModule,
		MatInputModule,
		AppRoutingModule,
		MatCheckboxModule,
		MatTabsModule,
		TreeSelectModule,
	],
	exports: [StartPageTableComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMAT }],
})
export class StartPageModule {}
