import { SafeHtml } from '@angular/platform-browser';

import { TestObject } from './testObject';
import { Property } from './property';
import { Version } from './version';
import { UF } from './uf';
import { ProductProperty } from './productProperty';
import { ECU } from './ecu';
import { OffboardComponent } from './offboardComponent';
import { TestAnalysisVersionResult } from './testAnalysis';

export class TestCase {
	public TCUID: string;

	public ItemNo: string;

	public Description: string;

	public DescriptionSafeHtml: SafeHtml;

	public Version: number;

	public Versions: Version;

	public UFs: UF[];

	public ProductProperties: ProductProperty[];

	public ECUs: ECU[];

	public OffboardComponents: OffboardComponent[];

	public RegTime: Date; // Creation time

	public RegBy: string;

	public Name: string;

	public Requirement: string;

	public InputTypeId: number;

	public Script: string;

	public ResultCount: number;

	public Owners: string[];

	public Operator: string;

	public Value: string; //value for operator used in conditional linking

	public ParentUID: string;

	public TestCases: TestCase[];
}

export class TestCaseWithResults {
	public TestCaseUid: string;

	public TestCaseName: string;

	public Results: TestAnalysisVersionResult[];

	public HighestVersion: number;
}

export class TestCaseAlternative {
	public UID: string;

	public Version: number;

	public Name: string;

	public Label: string;

	public FromDate: Date;

	public ToDate: Date;

	public ValidFromSop: string;

	public ValidToSop: string;

	public TestObject: TestObject;

	public RegBy: string;

	public RegTime: Date;

	public Properties: Property[];

	public TestGroups: string[];
}
