import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router

import { StartPageComponent } from 'src/modules/start-page/start-page.component';

import { TestRunComponent } from './components/test-run/test-run.component';
import { TestResultsComponent } from './components/test-results/test-results.component';
import { TestResultsPageComponent } from './test-results-page.component';
import { TestCaseDetailsComponent } from './components/test-case-details/test-case-details.component';
import { TestCasesComponent } from './components/test-cases/test-cases.component';
import { TestSuiteComponent } from './components/test-suite/test-suite.component';
import { TestSuitesComponent } from './components/test-suites/test-suites.component';
import { OverviewComponent } from './components/overview/overview.component';
import { TestCaseComponent } from './components/test-case/test-case.component';
import { RequirementFulfillmentComponent } from './components/requirement-fulfillment/requirement-fulfillment.component';
import { TestSpecificationsComponent } from './components/test-specifications/test-specifications.component';
import { TestSpecificationComponent } from './components/test-specification/test-specification.component';

const routes: Routes = [
	{
		path: 'testresult',
		component: TestResultsPageComponent,
		children: [
			{
				path: ':uid',
				component: TestResultsComponent,
			},
			{
				path: 'testcasedetails/:uid',
				component: TestCaseDetailsComponent,
			},
			{
				path: 'testrun/:uid',
				component: TestRunComponent,
			},
		],
	},
	{
		//list of test case results
		path: 'testcase/:uid',
		component: TestCasesComponent,
	},
	{
		//list of test runs for test suite
		path: 'testsuite/:uid',
		component: TestSuitesComponent,
	},
	{
		//list of test runs for test specification
		path: 'testspecification/:uid',
		component: TestSpecificationsComponent,
	},
	{
		//statistics
		path: 'overview',
		component: OverviewComponent,
	},
	{
		path: 'test-coverage',
		component: RequirementFulfillmentComponent,
	},
	{
		//show test runs on start page by property in querystring
		//example: {baseUrl}testrun/property?name=VersionFileCommitHash&value=706fa8f3
		path: 'testrun/property',
		component: StartPageComponent,
	},
	{
		//show test case and steps by test case uid and version in querystring
		//example: {baseUrl}}/testcasedetails?uid=35edd9bf-7b5b-4649-9788-58a7215d613d&version=2
		//also possible to omit version and get the latest test suite, example: {baseUrl}}/testcasedetails?uid=35edd9bf-7b5b-4649-9788-58a7215d613d
		path: 'testcasedetails',
		component: TestCaseComponent,
	},
	{
		//show test suite details by test suite uid and version in querystring
		//example: {baseUrl}}/testsuitedetails?uid=35edd9bf-7b5b-4649-9788-58a7215d613d&version=2
		//also possible to omit version and get the latest test suite, example: {baseUrl}}/testsuitedetails?uid=35edd9bf-7b5b-4649-9788-58a7215d613d
		path: 'testsuitedetails',
		component: TestSuiteComponent,
	},
	{
		//show test specification details by test specification uid and version in querystring
		//example: {baseUrl}}/testspecificationdetails?uid=35edd9bf-7b5b-4649-9788-58a7215d613d&version=2
		//also possible to omit version and get the latest test specification, example: {baseUrl}}/testspecificationdetails?uid=35edd9bf-7b5b-4649-9788-58a7215d613d
		path: 'testspecificationdetails',
		component: TestSpecificationComponent,
	},
]; // sets up routes constant where you define your routes

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class TestResultsPageRoutingModule {}
