import { gql } from 'apollo-angular';

export const TEST_RUN_PROPERTIES_QUERY = gql`
	query resultsPropertiesForTestRun($Uid: ID!) {
		resultsPropertiesForTestRun(TestRunUid: $Uid) {
			Description
			FieldFormat
			Identifier
			Name
			Value
			ValueDescription
			Variant
			Version
			Label
		}
	}
`;

export const TEST_RUN_STATISTICS_QUERY = gql`
	query resultsStatisticsForTestRun($Uid: ID!) {
		resultsStatisticsForTestRun(Uid: $Uid) {
			InputStatistics {
				Count
				Type
			}
		}
	}
`;

export const TEST_RUNS_BY_TEST_CASE_UID_QUERY = gql`
	query resultsTestRunModelsByTestCase($Uid: ID!) {
		resultsTestRunModelsByTestCase(TestCaseUid: $Uid) {
			TestSuite {
				TestSuiteUid
				ItemNo
				Version
				Name
			}
			TestSpecification {
				UID
				Version
				Name
				ItemNo
			}
			TestRun {
				TestRunUid
				VinNo
				Status
				Classification
				ClassificationReason
				Environment
				ExecutionTime
				RegBy
				RegTime
				TestResults {
					TestResultUid
					Result
					ResultComment
					TestCaseVersion
					TestCaseName
					PositiveComment
					NegativeComment
					troubleReports {
						Name
						Uri
						TestResultUid
						ValidFrom
						ValidTo
					}
				}
			}
			TestRunType
		}
	}
`;

export const TEST_RUNS_BY_TEST_SUITE_UID_QUERY = gql`
	query resultsTestRunModelsByTestSuite($Uid: ID!) {
		resultsTestRunModelsByTestSuite(TestSuiteUid: $Uid) {
			TestRun {
				TestRunUid
				Environment
				ExecutionTime
				RegBy
			}
			TestSuite {
				TestSuiteUid
				ItemNo
				Version
				Name
				ParentTestSuiteUid
				ParentVersion
			}
			TestSuiteVersion
		}
	}
`;

export const TEST_RUNS_BY_TEST_SPECIFICATION_UID_QUERY = gql`
	query resultsTestRunModelsByTestSpecification($Uid: ID!) {
		resultsTestRunModelsByTestSpecification(TestSpecificationUid: $Uid) {
			TestRun {
				TestRunUid
				Environment
				ExecutionTime
				RegBy
			}
			TestSpecification {
				UID
				Version
				Name
				ItemNo
			}
		}
	}
`;

export const TEST_RUNS_BY_DATE_QUERY = gql`
	query resultsTestRunModelByDates($StartDate: String!, $EndDate: String!) {
		resultsTestRunModelByDates(StartDate: $StartDate, EndDate: $EndDate) {
			TestRun {
				TestRunUid
				ExecutionTime
				Environment
				VinNo
				Classification
				ClassificationReason
				Status
				RegBy
				RegTime
			}
			TestRunType
			TestSpecification {
				ItemNo
				Name
				UID
				Version
			}
			TestSuite {
				Name
				TestSuiteUid
				ItemNo
				Version
			}
			TestSuiteVersion
			EcuAndSwIdentifiers {
				Name
				SwIdentifiers
			}
		}
	}
`;

export const COMPLETE_TEST_RUN_QUERY = gql`
	query resultsCompleteTestRun($Uid: ID!) {
		resultsCompleteTestRun(Uid: $Uid) {
			TestRun {
				Classification
				ClassificationReason
				ClassificationComment
				Configuration
				SOP
				TestPeriod
				Environment
				ExecutionTime
				RegBy
				RegTime
				Status
				TestRunUid
				VinNo
				TestResults {
					FailedReason
					TestCaseName
					TestCaseUid
					TestCaseItemNo
					TestResultUid
					Result
					TestSuiteUid
					TestSuiteItemNo
					TestSuiteName
					TestSpecificationUid
					TestSpecificationName
					testCaseAlternativeFailedReasons {
						TestCaseAlternativeFailedReason
						failedComment
						failedBy
						testCaseAlternativeName
						testCaseAlternativeUID
						testCaseAlternativeVersion
					}
					troubleReports {
						Name
						Uri
						TestResultUid
						ValidFrom
						ValidTo
					}
					ResultComment
					FailedReasonText
					FailedBy
					FailedReasonComment
					PositiveComment
					NegativeComment
				}
			}
			TestRunType
			TestSpecification {
				ItemNo
				Name
				UID
				Version
			}
			TestSuite {
				Name
				TestSuiteUid
				ItemNo
				Version
			}
			TestSuiteVersion
		}
	}
`;

export const ECU_READOUTS_FOR_TEST_RUN_QUERY = gql`
	query resultsEcuReadoutsForTestRun($TestRunUid: ID!) {
		resultsEcuReadoutsForTestRun(TestRunUid: $TestRunUid) {
			EcuDDB
			EcuIdentification
			EcuName
			FileTime
			Name
			OriginTypeUid
			TestRunUid
			Value
		}
	}
`;

export const REQUIREMENTS_BY_TEST_CASE_QUERY = gql`
	query resultsRequirementsWithTestAnalysisByTestCase($TestCaseUid: ID!, $TestCaseVersion: Int) {
		resultsRequirementsWithTestAnalysisByTestCase(TestCaseUid: $TestCaseUid, TestCaseVersion: $TestCaseVersion) {
			Requirement {
				ASIL
				IrmaVersion
				Name
				RequirementID
				Version
			}
			TestAnalysisAlternative {
				Name
				RegBy
				RegTime
				UID
				ValidTo
				Version
			}
			TestAnalysis {
				Name
				RegBy
				RegTime
				UID
				ItemNo
				ValidTo
				Version
			}
		}
	}
`;

export const REQUIREMENTS_QUERY = gql`
	query resultsRequirementsWithTestAnalysis($TestResultUid: ID!) {
		resultsRequirementsWithTestAnalysis(TestResultUid: $TestResultUid) {
			Requirement {
				ASIL
				IrmaVersion
				Name
				RequirementID
				Version
			}
			TestAnalysisAlternative {
				Name
				RegBy
				RegTime
				UID
				ValidTo
				Version
			}
			TestAnalysis {
				Name
				RegBy
				RegTime
				UID
				ItemNo
				ValidTo
				Version
			}
		}
	}
`;

export const OFFBOARD_COMPONENTS_QUERY = gql`
	query resultsOffboardComponentsForTestRun($TestRunUid: ID!) {
		resultsOffboardComponentsForTestRun(TestRunUid: $TestRunUid) {
			FileUID
			OffboardComponent {
				Identifier
				UID
				Value
				Variant
				Version
			}
			RegTime
			TPC
			TypeUID
		}
	}
`;

export const SOPS_QUERY = gql`
	query resultsSopsForTestRun($TestRunUid: ID!) {
		resultsSopsForTestRun(TestRunUid: $TestRunUid) {
			FPCs {
				FamilyDescription
				Name
				Value
				VariantDescription
			}
			FileName
			FileTime
			FileUid
			FunctionParameters {
				Family
				Name
				ParentName
				Value
			}
			ImportTime
			OriginTypeUid
			RegBy
			RegTime
			VehicleName
			VINNo
			VersionControlVersion
			XPCs {
				Name
				Value
			}
		}
	}
`;

export const TEST_CASE_STEPS_QUERY = gql`
	query resultsTestCaseSteps($uid: ID!, $version: Int) {
		resultsTestCaseSteps(TestCaseUid: $uid, TestCaseVersion: $version) {
			ExpectedResponse
			Postcondition
			Precondition
			StepNo
			Stimuli
		}
	}
`;

export const TEST_CASE_ALTERNATIVES_QUERY = gql`
	query resultsTestCaseAlternatives($uid: ID!, $version: Int) {
		resultsTestCaseAlternatives(TestCaseUid: $uid, TestCaseVersion: $version) {
			FromDate
			TestObjects {
				Name
				TypeID
				UID
			}
			Properties {
				Description
				FieldFormat
				Identifier
				Name
				Label
				Value
				ValueDescription
				Variant
				Version
			}
			Label
			Name
			RegBy
			RegTime
			TestGroups
			ToDate
			UID
			Version
			ValidFromSop
			ValidToSop
		}
	}
`;

export const TEST_CASE_DETAILS_QUERY = gql`
	query resultsTestCaseDetails($uid: ID!, $version: Int) {
		resultsTestCaseDetails(TestCaseUid: $uid, TestCaseVersion: $version) {
			...testCaseProperties
			TestCases {
				...testCaseProperties
				TestCases {
					...testCaseProperties
					TestCases {
						...testCaseProperties
						TestCases {
							...testCaseProperties
							TestCases {
								...testCaseProperties
								TestCases {
									...testCaseProperties
								}
							}
						}
					}
				}
			}
		}
	}
	fragment testCaseProperties on ResultsTestCase {
		TCUID
		ItemNo
		Description
		Version
		Owners
		RegBy
		RegTime
		Name
		Requirement
		InputTypeId
		Script
		ResultCount
		LinkUID
		ParentUID
		Operator
		Value
		NodeOrder
		RestrictedViewAccess
		Steps {
			StepNo
			Precondition
			Stimuli
			ExpectedResponse
			Postcondition
		}
		Versions {
			ItemUID
			VersionNo
			ValidTo
			RegBy
			RegTime
		}
		UFs {
			UFNumber
			Name
		}
		ProductProperties {
			UID
			Variant
			Name
			ProductCategoryUID
			ProductCategoryName
		}
		ECUs {
			id
			name
			description
			fpcCondition
			familyId
			familyName
		}
		OffboardComponents {
			UID
			Value
			Version
			Identifier
			Variant
		}
		Alternatives {
			UID
			Version
			Name
			Label
			FromDate
			ToDate
			ValidFromSop
			ValidToSop
			RegBy
			RegTime
			TestGroups
			TestObjects {
				UID
				Name
				TypeID
			}
			Properties {
				Name
				Description
				Version
				Value
				ValueDescription
				FieldFormat
				Identifier
				Variant
				Label
			}
		}
	}
`;

export const TEST_CASE_QUERY = gql`
	query resultsTestCase($uid: ID!, $version: Int) {
		resultsTestCase(TestCaseUid: $uid, TestCaseVersion: $version) {
			...testCaseProperties
			TestCases {
				...testCaseProperties
				TestCases {
					...testCaseProperties
					TestCases {
						...testCaseProperties
						TestCases {
							...testCaseProperties
							TestCases {
								...testCaseProperties
								TestCases {
									...testCaseProperties
								}
							}
						}
					}
				}
			}
		}
	}
	fragment testCaseProperties on ResultsTestCase {
		TCUID
		ItemNo
		Description
		Version
		Owners
		RegBy
		RegTime
		Name
		Requirement
		InputTypeId
		Script
		ResultCount
		LinkUID
		ParentUID
		Operator
		Value
		NodeOrder
		RestrictedViewAccess
		Steps {
			StepNo
			Precondition
			Stimuli
			ExpectedResponse
			Postcondition
		}
		Versions {
			ItemUID
			VersionNo
			ValidTo
			RegBy
			RegTime
		}
		UFs {
			UFNumber
			Name
		}
		ProductProperties {
			UID
			Variant
			Name
			ProductCategoryUID
			ProductCategoryName
		}
		ECUs {
			id
			name
			description
			fpcCondition
			familyId
			familyName
		}
		OffboardComponents {
			UID
			Value
			Version
			Identifier
			Variant
		}
		Alternatives {
			UID
			Version
			Name
			Label
			FromDate
			ToDate
			ValidFromSop
			ValidToSop
			RegBy
			RegTime
			TestGroups
			TestObjects {
				UID
				Name
				TypeID
			}
			Properties {
				Name
				Description
				Version
				Value
				ValueDescription
				FieldFormat
				Identifier
				Variant
				Label
			}
		}
	}
`;

export const TEST_RESULT_QUERY = gql`
	query resultsTestResult($Uid: ID!) {
		resultsTestResult(Uid: $Uid) {
			Classification
			ClassificationComment
			ClassificationReason
			ErrorText
			ExecutedBy
			ExecutionTimeTestResult
			ExecutionTimeTestRun
			FailedBy
			FailedReason
			FailedReasonComment
			FailedReasonText
			InputType
			MainTestSuiteName
			MainTestSuiteUid
			MainTestSuiteItemNo
			PositiveComment
			NegativeComment
			MainTestSuiteVersion
			Result
			ResultText
			ResultComment
			TestCaseName
			TestCaseRestrictedViewAccess
			TestCaseUid
			TestCaseItemNo
			TestCaseVersion
			TestResultUid
			TestRunType
			TestRunUid
			TestSpecificationItemNo
			TestSpecificationName
			TestSpecificationUid
			TestSpecificationVersion
			TestSuiteName
			TestSuiteVersion
			TestSuiteUid
			TestSuiteItemNo
			VINNo
			LinkUid
			VehicleName
			ecus {
				description
				familyId
				familyName
				fpcCondition
				id
				name
			}
			offboardComponents {
				Identifier
				UID
				Value
				Variant
				Version
			}
			testCaseAlternativeFailedReasons {
				TestCaseAlternativeFailedReason
				failedBy
				failedComment
				testCaseAlternativeName
				testCaseAlternativeUID
				testCaseAlternativeVersion
			}
			troubleReports {
				Name
				TestResultUid
				Uri
				ValidFrom
				ValidTo
			}
		}
	}
`;

export const RESULT_PROPERTIES_QUERY = gql`
	query resultsPropertiesForTestResult($TestResultUid: ID!) {
		resultsPropertiesForTestResult(TestResultUid: $TestResultUid) {
			Description
			FieldFormat
			Identifier
			Label
			Name
			Value
			ValueDescription
			Variant
			Version
		}
	}
`;

export const TEST_SPECIFICATION_QUERY = gql`
	query resultsTestSpecification($Uid: ID!, $version: Int) {
		resultsTestSpecification(Uid: $Uid, Version: $version) {
			UID
			ItemNo
			Name
			Version
			Owners
			TestAnalyses {
				UID
				ItemNo
				Name
				Version
				RegBy
				RegTime
			}
			RegTime
			RegBy
			Versions {
				ItemUID
				VersionNo
				ValidTo
				RegBy
				RegTime
			}
		}
	}
`;

export const TEST_SUITE_DETAILS_QUERY = gql`
	query resultsTestSuite($uid: ID!, $version: Int) {
		resultsTestSuite(Uid: $uid, Version: $version) {
			TestSuiteUid
			ItemNo
			Name
			Version
			UFs {
				Name
				UFNumber
			}
			ProductProperties {
				Name
				ProductCategoryName
				ProductCategoryUID
				UID
				Variant
			}
			Versions {
				ItemUID
				RegBy
				RegTime
				ValidTo
				VersionNo
			}
			OffboardComponents {
				Identifier
				UID
				Value
				Version
				Variant
			}
			Owners
			ParentTestSuiteUid
			ParentVersion
			Description
			RegBy
			RegTime
			ParentUID
			LinkUID
			NodeOrder
		}
	}
`;

export const TEST_CASES_FOR_TEST_SUITE_QUERY = gql`
	query resultsTestCasesForTestSuite($uid: ID!, $version: Int) {
		resultsTestCasesForTestSuite(TestSuiteUid: $uid, TestSuiteVersion: $version) {
			Alternatives {
				FromDate
				Label
				Name
				Properties {
					FieldFormat
					Description
					Identifier
					Label
					Name
					Value
					ValueDescription
					Variant
					Version
				}
				RegBy
				RegTime
				TestGroups
				TestObjects {
					Name
					TypeID
					UID
				}
				ToDate
				UID
				ValidFromSop
				ValidToSop
				Version
			}
			Description
			ECUs {
				description
				familyId
				familyName
				fpcCondition
				id
				name
			}
			InputTypeId
			LinkUID
			Name
			NodeOrder
			OffboardComponents {
				Identifier
				UID
				Value
				Variant
				Version
			}
			Operator
			Owners
			ParentUID
			ProductProperties {
				Name
				ProductCategoryName
				ProductCategoryUID
				UID
				Variant
			}
			RegBy
			RegTime
			Requirement
			RestrictedViewAccess
			ResultCount
			Script
			Steps {
				ExpectedResponse
				Postcondition
				Precondition
				StepNo
				Stimuli
			}
			TCUID
			ItemNo
			TestCases {
				Alternatives {
					FromDate
					Label
					Name
					Properties {
						Description
						FieldFormat
						Identifier
						Label
						Name
						Value
						ValueDescription
						Variant
						Version
					}
					RegBy
					RegTime
					TestGroups
					TestObjects {
						Name
						TypeID
						UID
					}
					ToDate
					UID
					ValidFromSop
					ValidToSop
					Version
				}
				Description
				ECUs {
					description
					familyId
					familyName
					fpcCondition
					id
					name
				}
				InputTypeId
				LinkUID
				Name
				NodeOrder
				OffboardComponents {
					Identifier
					UID
					Value
					Variant
					Version
				}
				Operator
				Owners
				ParentUID
				ProductProperties {
					ProductCategoryName
					ProductCategoryUID
					UID
					Variant
				}
				RegBy
				RegTime
				Requirement
				RestrictedViewAccess
				ResultCount
				Script
				Steps {
					ExpectedResponse
					Postcondition
					Precondition
					StepNo
					Stimuli
				}
				TCUID
				ItemNo
				Value
				Version
				Versions {
					ItemUID
					RegBy
					RegTime
					ValidTo
					VersionNo
				}
				UFs {
					Name
					UFNumber
				}
			}
			UFs {
				Name
				UFNumber
			}
			Value
			Versions {
				ItemUID
				RegBy
				RegTime
				ValidTo
				VersionNo
			}
			Version
		}
	}
`;

export const ALTERNATIVES_FOR_TEST_SUITE_QUERY = gql`
	query resultsTestSuiteAlternativesForTestSuite($uid: ID!, $version: Int) {
		resultsTestSuiteAlternativesForTestSuite(TestSuiteUid: $uid, TestSuiteVersion: $version) {
			FromDate
			Label
			Name
			Properties {
				Description
				FieldFormat
				Identifier
				Label
				Name
				Value
				ValueDescription
				Variant
				Version
			}
			RegBy
			RegTime
			TestGroups
			ToDate
			UID
			ValidFromSop
			ValidToSop
			Version
		}
	}
`;

export const TEST_SUITE_QUERY = gql`
	query resultsTestSuite($uid: ID!, $version: Int) {
		resultsTestSuite(Uid: $uid, Version: $version) {
			TestSuiteUid
			ItemNo
			Name
			Owners
			UFs {
				Name
				UFNumber
			}
			Version
			ProductProperties {
				Name
				ProductCategoryName
				ProductCategoryUID
				UID
				Variant
			}
			Versions {
				ItemUID
				RegBy
				RegTime
				ValidTo
				VersionNo
			}
			OffboardComponents {
				Identifier
				UID
				Value
				Variant
				Version
			}
			ParentTestSuiteUid
			ParentVersion
			ChildrenTestSuites {
				TestRun {
					Classification
					ClassificationComment
					ClassificationReason
					Configuration
					Environment
					ExecutionTime
					RegBy
					RegTime
					SOP
					Status
					TestPeriod
					TestRunUid
					VinNo
				}
				TestRunType
				TestSpecification {
					ItemNo
					Name
					Owners
					RegBy
					RegTime
					UID
					Version
				}
			}
			RegBy
			RegTime
			ParentUID
			NodeOrder
			LinkUID
			Description
		}
	}
`;

export const TEST_SUITES_FOR_TEST_SUITE_QUERY = gql`
	query resultsTestSuitesForTestSuite($uid: ID!, $version: Int) {
		resultsTestSuitesForTestSuite(Uid: $uid, Version: $version) {
			Name
			NodeOrder
			Owners
			RegTime
			TestSuiteUid
			RegBy
			Version
			TestSuites {
				Name
				NodeOrder
				Owners
				RegBy
				RegTime
				TestSuiteUid
				Version
				TestSuites {
					Name
					NodeOrder
					RegBy
					RegTime
					TestSuiteUid
					ItemNo
					Version
				}
			}
		}
	}
`;

export const TROUBLE_REPORTS_FOR_TEST_RESULT_QUERY = gql`
	query resultsTroubleReportsForTestResult($TestResultUid: ID!) {
		resultsTroubleReportsForTestResult(TestResultUid: $TestResultUid) {
			Name
			TestResultUid
			Uri
			ValidFrom
			ValidTo
		}
	}
`;
