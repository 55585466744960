import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { TROUBLE_REPORTS_FOR_TEST_RESULT_QUERY } from 'src/graphql/queries/abstraction-layer-queries';
import { TroubleReport } from 'src/models/troubleReport';

import { ApolloService } from './apollo.service';
import { TroubleReportBaseService } from './trouble-report.base.service';

@Injectable({ providedIn: 'root' })
export class TroubleReportGraphQLService extends TroubleReportBaseService {
	constructor(private apolloService: ApolloService) {
		super();
	}

	public getTroubleReports(testResultUid: string): Observable<TroubleReport[]> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsTroubleReportsForTestResult: TroubleReport[] }>({
					query: TROUBLE_REPORTS_FOR_TEST_RESULT_QUERY,
					variables: { TestResultUid: testResultUid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsTroubleReportsForTestResult) {
					return result.data.resultsTroubleReportsForTestResult;
				} else {
					return <TroubleReport[]>[];
				}
			}),
			shareReplay(1),
		);
	}
}
