import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { TmtLoggerService } from 'tmt-logger';

import { TestSuiteAlternative } from 'src/models/testRun';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';

@Component({
	selector: 'app-test-suite-alternative',
	templateUrl: './test-suite-alternative.component.html',
	styleUrls: ['./test-suite-alternative.component.scss'],
})
export class TestSuiteAlternativeComponent implements OnChanges {
	alternatives: TestSuiteAlternative[];

	@Input() testSuiteUid: string;

	@Input() testSuiteVersion: number;

	dataSource: MatTableDataSource<TestSuiteAlternative>;

	displayedColumns: string[] = ['Properties', 'Version', 'TestGroups', 'RegTime', 'RegBy', 'UID'];

	constructor(
		private testSuiteBaseService: TestSuiteBaseService,
		private loggerService: TmtLoggerService,
	) {}

	ngOnChanges(): void {
		if (this.testSuiteVersion !== undefined) {
			this.getAlternativesForTestSuite(this.testSuiteUid, this.testSuiteVersion);
		} else {
			this.getAlternativesForLatestTestSuite(this.testSuiteUid);
		}
	}

	private getAlternativesForTestSuite(tsUid: string, tsVersion: number) {
		this.testSuiteBaseService.getAlternativesForTestSuite(tsUid, tsVersion).subscribe(
			alt => {
				this.alternatives = alt;
				const dataSource = new MatTableDataSource<TestSuiteAlternative>(this.alternatives);
				//   dataSource.sort = this.sort;
				// dataSource.paginator = this.paginator;
				this.dataSource = dataSource;
			},
			error => {
				this.loggerService.logError(error);
			},
		);
	}

	private getAlternativesForLatestTestSuite(tsUid: string) {
		this.testSuiteBaseService.getAlternativesForLatestTestSuite(tsUid).subscribe(
			alt => {
				this.alternatives = alt;
				const dataSource = new MatTableDataSource<TestSuiteAlternative>(this.alternatives);
				//   dataSource.sort = this.sort;
				// dataSource.paginator = this.paginator;
				this.dataSource = dataSource;
			},
			error => {
				this.loggerService.logError(error);
			},
		);
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
