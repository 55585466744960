<div class="row left-margin right-margin">
	<div class="col-md-12" [ngClass]="{ loader: gettingTestSuite }"></div>
	@if (testSuiteExist && !gettingTestSuite) {
		<div class="col-md-12">
			<div id="divTestSuiteHeading" class="col-md-12 no-padding bottom-margin">
				<div class="results-heading">
					<h2>Details page for test suite: {{ testSuite?.ItemNo }} - {{ testSuite?.Name }}</h2>
				</div>
				<div class="results-heading-details">
					&nbsp;(version {{ testSuite?.Version }}) - {{ testSuite?.RegTime | date: 'yyyy-MM-dd HH:mm:ss' }} - {{ testSuite?.RegBy | uppercase }}&nbsp;
					<span [routerLink]="['/testsuite/', testSuite?.TestSuiteUid]" class="scania-link small-font">[View all results for this test suite]</span>
				</div>
			</div>
			<div>
				<button mat-button (click)="accordion.openAll()" class="btn btn-sm btn-primary">Expand all panels</button>
				<button mat-button (click)="accordion.closeAll()" class="btn btn-sm btn-default">Collapse all panels</button>
			</div>
			<mat-accordion multi>
				<div class="bottom-margin-small">
					<mat-expansion-panel [expanded]="true" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>Details</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divTestSuiteDetails" *ngIf="queryParamUid">
							<app-test-suite-details [testSuiteUid]="queryParamUid" [testSuiteVersion]="queryParamVersion"></app-test-suite-details>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel [expanded]="true" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>Test cases &nbsp;&nbsp;</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divTestCasesForTestSuite" *ngIf="queryParamUid">
							<app-test-suite-testcases [testSuiteUid]="queryParamUid" [testSuiteVersion]="queryParamVersion"></app-test-suite-testcases>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel [expanded]="true" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>Test suites &nbsp;&nbsp;</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divTestSuitesForTestSuite" *ngIf="queryParamUid">
							<app-test-suite-testsuites [testSuiteUid]="queryParamUid" [testSuiteVersion]="queryParamVersion"></app-test-suite-testsuites>
						</div>
					</mat-expansion-panel>
				</div>
				<div class="bottom-margin-small">
					<mat-expansion-panel [expanded]="true" class="bottom-margin">
						<mat-expansion-panel-header>
							<mat-panel-title>Test suite alternatives for {{ testSuite?.Name }}&nbsp;&nbsp;</mat-panel-title>
						</mat-expansion-panel-header>
						<div id="divAlternativesForTestSuite" *ngIf="queryParamUid">
							<app-test-suite-alternative [testSuiteUid]="queryParamUid" [testSuiteVersion]="queryParamVersion"></app-test-suite-alternative>
						</div>
					</mat-expansion-panel>
				</div>
			</mat-accordion>
		</div>
	} @else if (!testSuiteExist && !gettingTestSuite) {
		<div>Test suite not found</div>
	}
</div>
