import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RequirementWithTestAnalysis } from 'src/models/requirement';

import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export abstract class RequirementBaseService extends BaseAPIService {
	public abstract getRequirementsByTestCase(testCaseUid: string, testCaseVersion: number): Observable<RequirementWithTestAnalysis[]>;

	public abstract getRequirementsByResult(testResultUid: string): Observable<RequirementWithTestAnalysis[]>;
}
