import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { SOPS } from 'src/models/sops';

import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export abstract class SopsBaseService extends BaseAPIService {
	public abstract getSOPS(runUid: string): Observable<SOPS>;
}
