<div class="row left-margin right-margin">
	<div class="col-md-12">
		<div *ngIf="this.testSpecifications?.length > 0">
			<div class="col-md-10">
				<h2>Test runs for test specification: {{ this.activeTestSpecification?.ItemNo }} {{ this.activeTestSpecification?.Name }}&nbsp;</h2>
				<span class="small-font-italic">Select test run to view test results</span>
			</div>
			<div class="col-md-2 align-right no-padding">
				<div class="col-md-12 no-padding">
					<input type="text" id="txtSearchTestSpecification" class="form-control" placeholder="Filter..." (keyup)="applyFilter($event)" />
				</div>
			</div>
			<div class="col-md-12 mat-elevation-z0">
				<table mat-table [dataSource]="dataSource" matSort>
					<ng-container matColumnDef="TestRun.TestRunUid">
						<th mat-header-cell *matHeaderCellDef mat-sort-header width="15%">Test run UID</th>
						<td mat-cell *matCellDef="let element">{{ element.TestRun.TestRunUid }}</td>
					</ng-container>
					<ng-container matColumnDef="TestRun.Environment">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Environment</th>
						<td mat-cell *matCellDef="let element">{{ element.TestRun.Environment }}</td>
					</ng-container>
					<ng-container matColumnDef="TestSpecification.Version">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Test specification version</th>
						<td mat-cell *matCellDef="let element">{{ element.TestSpecification.Version }}</td>
					</ng-container>
					<ng-container matColumnDef="TestRun.ExecutionTime">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Execution time</th>
						<td mat-cell *matCellDef="let element">{{ element.TestRun.ExecutionTime | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
					</ng-container>
					<ng-container matColumnDef="TestRun.RegBy">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Executed by</th>
						<td mat-cell *matCellDef="let element">{{ element.TestRun.RegBy }}</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedColumns"
						[ngClass]="{ hovered: row.hovered }"
						(focus)="row.hovered = true"
						(mouseover)="row.hovered = true"
						(blur)="row.hovered = false"
						(mouseout)="row.hovered = false"
						(click)="navigateTo(row)"
					></tr>
				</table>
				<mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
			</div>
		</div>
	</div>
	<div *ngIf="this.testSpecifications?.length === 0">
		<h4>There are no test runs for this test specification.</h4>
	</div>
</div>
