<div class="col-md-12" [ngClass]="{ loader: gettingTestCases }"></div>
<div class="col-md-12" *ngIf="testCases?.length > 0 && !gettingTestCases">
	<table mat-table [dataSource]="dataSource">
		<ng-container matColumnDef="Name">
			<th mat-header-cell *matHeaderCellDef>
				<span [style.paddingLeft.px]="40">Name</span>
			</th>
			<td mat-cell *matCellDef="let data">
				<button mat-icon-button class="expand-button" [style.visibility]="!data.expandable ? 'hidden' : ''" [style.marginLeft.px]="data.level * 20" (click)="treeControl.toggle(data)">
					<mat-icon class="mat-icon-rtl-mirror">
						{{ treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right' }}
					</mat-icon>
				</button>
				<span class="align-top bold" *ngIf="data.parentuid !== '00000000-0000-0000-0000-000000000000'">[{{ data.operator }}{{ data.value }}]&nbsp;{{ data.itemno }} - {{ data.name }}</span>
				<span class="align-top bold" *ngIf="data.parentuid === '00000000-0000-0000-0000-000000000000'">{{ data.itemno }} - {{ data.name }}</span>
			</td>
		</ng-container>
		<ng-container matColumnDef="Version">
			<th mat-header-cell *matHeaderCellDef width="5%">Version</th>
			<td mat-cell *matCellDef="let data">{{ data.version }}</td>
		</ng-container>
		<ng-container matColumnDef="RegTime">
			<th mat-header-cell *matHeaderCellDef width="10%">Creation time</th>
			<td mat-cell *matCellDef="let data">{{ data.regTime | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
		</ng-container>
		<ng-container matColumnDef="RegBy">
			<th mat-header-cell *matHeaderCellDef width="10%">Created by</th>
			<td mat-cell *matCellDef="let data">{{ data.regBy }}</td>
		</ng-container>
		<ng-container matColumnDef="TCUID">
			<th mat-header-cell *matHeaderCellDef width="20%">Test case UID</th>
			<td mat-cell *matCellDef="let data">{{ data.TCUID }}</td>
		</ng-container>
		<ng-container matColumnDef="GoTo">
			<th mat-header-cell *matHeaderCellDef width="20%">Go to</th>
			<td mat-cell *matCellDef="let data" (click)="navigateTo(data)" class="scania-link">link to test case details page</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr
			mat-row
			*matRowDef="let row; columns: displayedColumns"
			[ngClass]="{ hovered: row.hovered }"
			(focus)="row.hovered = true"
			(mouseover)="row.hovered = true"
			(blur)="row.hovered = false"
			(mouseout)="row.hovered = false"
		></tr>
	</table>
</div>

<div *ngIf="testCases?.length === 0">
	<span class="scania-icon-info xs"></span>
	<span class="bold">No test cases found or you are not authorized to view them</span>
</div>
