<p-toast position="bottom-right"></p-toast>
<div class="row left-margin">
	<div class="col-md-12">
		<div class="align-left no-padding">
			<mat-form-field class="mat-form-field">
				<mat-label>Specification ID</mat-label>
				<input matInput #txtSearchSpecification title='Enter the ID that the specification has in Polarion, e.g. "ID-41320"' />
			</mat-form-field>
			&nbsp;&nbsp;
			<mat-form-field class="mat-form-field specification-version-input">
				<mat-label>Version</mat-label>
				<input
					matInput
					type="number"
					min="0"
					#txtSearchIrmaVersion
					title='Enter the Irma version that the specification has in Polarion, e.g. "2", if no version entered then latest version will be returned'
				/>
			</mat-form-field>
			&nbsp;&nbsp;
			<button mat-button class="btn btn-sm btn-default search-button" (click)="loadDataAndRenderTreeSync(txtSearchSpecification.value, txtSearchIrmaVersion.value)">&nbsp;Search</button>
		</div>
	</div>
	<div>
		<!-- Seems like we need an empty div here, else the top expansion panel wont show ??? -->
		<div>&nbsp;</div>
	</div>
	<span class="col-md-12" [ngClass]="isLoading ? 'loader' : 'display-none'"></span>
	<ng-template [ngIf]="!isLoading && specificationId">
		<div class="outer">
			<div class="horizontal-divs">
				<button mat-button class="btn btn-sm btn-default" (click)="changeSoftwareFilterVisibility()">
					Software filter&nbsp;
					<i class="fa fa-chevron-down" *ngIf="!showSoftwareFilter"></i>
					<i class="fa fa-chevron-up" *ngIf="showSoftwareFilter"></i>
				</button>
				<div [style]="showSoftwareFilter ? 'display:block' : 'display:none'" class="test-coverage-filter">
					<ng-template [ngIf]="ecuTreeDataSource.data.length > 0" [ngIfElse]="noSoftwareBlock">
						<mat-tree [dataSource]="ecuTreeDataSource" [treeControl]="ecuTreeControl" style="width: 750px">
							<!-- This is the tree node template for leaf nodes -->
							<mat-tree-node *matTreeNodeDef="let node" style="padding-left: 40px; padding-bottom: 25px">
								<div class="software-wrapper">
									<div class="first" style="padding-top: 5px">
										<input type="checkbox" value="{{ getTestRunUids(node.testRuns) }}" #softwarecheckboxes (change)="onSoftwareChange()" />
									</div>
									<div class="second">
										<div class="software-wrapper">
											<div class="first">Identifiers:</div>
											<div class="second-values">
												<span *ngFor="let identifier of node.identifiers">
													<strong>{{ identifier.Name }}</strong>
													: {{ identifier.Value }}&nbsp;
												</span>
											</div>
										</div>
										<div class="software-wrapper">
											<div class="first">Test runs executed on:</div>
											<div class="second-values">
												<span *ngFor="let testRun of node.testRuns; last as isLast" style="white-space: nowrap">
													<a href="/#/testresult/testrun/{{ testRun.TestRunUid }}" target="_blank">{{ formatTestRunDate(testRun.ExecutionTime, isLast) }}&nbsp;</a>
												</span>
											</div>
										</div>
									</div>
								</div>
							</mat-tree-node>
							<!-- This is the tree node template for expandable nodes -->
							<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
								<button class="mat-button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
									<mat-icon class="mat-icon-rtl-mirror">
										{{ ecuTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
									</mat-icon>
								</button>
								<div>
									<div class="nodename">&nbsp;{{ node.name }}</div>
								</div>
							</mat-tree-node>
						</mat-tree>
					</ng-template>
					<ng-template #noSoftwareBlock>No software to filter on</ng-template>
				</div>
			</div>
			&nbsp;&nbsp;
			<div class="horizontal-divs">
				<button class="btn btn-sm btn-default" (click)="changePropertyFilterVisibility()">
					Properties filter&nbsp;
					<i class="fa fa-chevron-down" *ngIf="!showPropertyFilter"></i>
					<i class="fa fa-chevron-up" *ngIf="showPropertyFilter"></i>
				</button>
				<div [style]="showPropertyFilter ? 'display:block' : 'display:none'" class="test-coverage-filter property-filter">
					<ng-template [ngIf]="allPropertyValuesForAllTestRuns !== null && allPropertyValuesForAllTestRuns.length > 0" [ngIfElse]="noPropertiesBlock">
						<span *ngFor="let property of allPropertyValuesForAllTestRuns; let i = index">
							<div class="horizontal-multiselect">
								<p-multiSelect
									[options]="property.filterValues"
									#propertyDropdowns
									defaultLabel="Select {{ property.name }}"
									[filter]="true"
									optionLabel="filterValue"
									name="valuesFor{{ property.name }}"
									(onChange)="onPropertyValueChange($event, property.name)"
								></p-multiSelect>
							</div>
						</span>
					</ng-template>
					<ng-template #noPropertiesBlock>No properties to filter on</ng-template>
				</div>
			</div>
			&nbsp;&nbsp;
			<div class="horizontal-divs">
				<button class="btn btn-sm btn-default" (click)="changeStatisticsVisibility()">
					Show statistics&nbsp;
					<i class="fa fa-chevron-down" *ngIf="!showStatistics"></i>
					<i class="fa fa-chevron-up" *ngIf="showStatistics"></i>
				</button>
			</div>
			<div [style]="showStatistics ? 'display:block' : 'display:none'" class="test-coverage-filter">
				<div>
					<div class="piechart" style="padding-left: 309px" id="divTestRunStatistics">
						<div>
							<h4 style="padding-left: 304px">Total test coverage (does not account for results for previous versions)</h4>
						</div>
						<div>
							<ngx-charts-pie-chart
								[view]="view"
								[scheme]="colorScheme"
								[results]="pieChartDataTestDesign"
								[gradient]="true"
								[legend]="true"
								[legendTitle]="''"
								[legendPosition]="'below'"
								[labels]="true"
								[trimLabels]="false"
								[doughnut]="false"
								(select)="onPieChartSelect($event.name)"
							></ngx-charts-pie-chart>
						</div>
					</div>
					<div class="col-md-10 piechart-asil" style="padding-top: 100px">
						<div style="padding-left: 309px">
							<h4>Test coverage per ASIL level [CURRENTLY HARD CODED - provided just as examples]</h4>
						</div>
						<div>
							<div class="col-md-5 piechart-asil" style="float: left">
								<span>ASIL QM</span>
								<ngx-charts-pie-chart
									[view]="viewAsil"
									[scheme]="colorSchemeAsilDiagrams"
									[results]="pieChartDataAsilQm"
									[gradient]="true"
									[legend]="false"
									[legendTitle]="''"
									[legendPosition]="'below'"
									[labels]="true"
									[trimLabels]="false"
									[doughnut]="false"
									(select)="onPieChartSelect($event.name)"
								></ngx-charts-pie-chart>
							</div>
							<div class="col-md-5 piechart-asil" style="float: right">
								<span>ASIL A</span>
								<ngx-charts-pie-chart
									[view]="viewAsil"
									[scheme]="colorSchemeAsilDiagrams"
									[results]="pieChartDataAsilA"
									[gradient]="true"
									[legend]="false"
									[legendTitle]="''"
									[legendPosition]="'below'"
									[labels]="true"
									[trimLabels]="false"
									[doughnut]="false"
									(select)="onPieChartSelect($event.name)"
								></ngx-charts-pie-chart>
							</div>
						</div>

						<div>
							<div class="col-md-5 piechart-asil" style="float: left">
								<span>ASIL B</span>
								<ngx-charts-pie-chart
									[view]="viewAsil"
									[scheme]="colorSchemeAsilDiagrams"
									[results]="pieChartDataAsilB"
									[gradient]="true"
									[legend]="false"
									[legendTitle]="''"
									[legendPosition]="'below'"
									[labels]="true"
									[trimLabels]="false"
									[doughnut]="false"
									(select)="onPieChartSelect($event.name)"
								></ngx-charts-pie-chart>
							</div>

							<div class="col-md-5 piechart-asil" style="float: right">
								<span>ASIL C</span>
								<ngx-charts-pie-chart
									[view]="viewAsil"
									[scheme]="colorSchemeAsilDiagrams"
									[results]="pieChartDataAsilC"
									[gradient]="true"
									[legend]="false"
									[legendTitle]="''"
									[legendPosition]="'below'"
									[labels]="true"
									[trimLabels]="false"
									[doughnut]="false"
									(select)="onPieChartSelect($event.name)"
								></ngx-charts-pie-chart>
							</div>
						</div>
						<div>
							<div class="col-md-5 piechart-asil">
								<span>ASIL D</span>
								<ngx-charts-pie-chart
									[view]="viewAsil"
									[scheme]="colorSchemeAsilDiagramsWithNotPerformed"
									[results]="pieChartDataAsilD"
									[gradient]="true"
									[legend]="false"
									[legendTitle]="''"
									[legendPosition]="'below'"
									[labels]="true"
									[trimLabels]="false"
									[doughnut]="false"
									(select)="onPieChartSelect($event.name)"
								></ngx-charts-pie-chart>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="header">
				<span>
					<h4>Requirements for specification {{ specificationId }}</h4>
				</span>
			</div>
		</div>
		<div class="col-md-12">
			<div>
				<label>
					Show results for all Test Analysis and Test Case versions&nbsp;
					<input
						class="checkbox"
						type="checkbox"
						[(ngModel)]="doShowResultsForAllVersions"
						(change)="clearFiltersAndRenderTree()"
						style="margin-bottom: 5px"
						[checked]="doShowResultsForAllVersions"
					/>
				</label>
			</div>
			<ng-template [ngIf]="this.filter.requirementFilter !== null">
				<div>
					<strong>Filtered on</strong>
					<span>{{ this.filter.requirementFilter.text }}</span>
				</div>
			</ng-template>
			<ng-template [ngIf]="this.filter.propertyFilterOnTestRuns !== null">
				<div>
					<div *ngFor="let property of this.selectedPropertyValues">
						<strong>Filtered on {{ property.name }}:</strong>
						<span *ngFor="let filterValue of property.filterValues; last as isLast">
							{{ formatList(filterValue.filterValue, isLast) }}
						</span>
					</div>
				</div>
				<div>
					<strong>Properties in Test Runs:</strong>
					<span *ngFor="let testRun of this.filter.propertyFilterOnTestRuns; last as isLast">
						<a href="/#/testresult/testrun/{{ testRun.TestRunUid }}" target="_blank">{{ formatTestRunDate(testRun.ExecutionTime, isLast) }}&nbsp;&nbsp;</a>
					</span>
				</div>
			</ng-template>
			<ng-template [ngIf]="this.filter.softwareFilterOnTestRuns !== null">
				<div>
					<strong>Filtered on software in Test Runs executed on:</strong>
					<span *ngFor="let testRun of this.filter.softwareFilterOnTestRuns; last as isLast">
						<a href="/#/testresult/testrun/{{ testRun.TestRunUid }}" target="_blank">{{ formatTestRunDate(testRun.ExecutionTime, isLast) }}&nbsp;&nbsp;</a>
					</span>
				</div>
			</ng-template>
			<ng-template [ngIf]="this.filter.requirementFilter !== null || this.filter.propertyFilterOnTestRuns !== null || this.filter.softwareFilterOnTestRuns !== null">
				<span>
					<button mat-button class="btn btn-sm btn-default" (click)="onClearFilterClick()">&nbsp;Clear filter</button>
				</span>
			</ng-template>
			<mat-tree [dataSource]="requirementTreeDataSource" [treeControl]="requirementTreeControl">
				<!-- This is the tree node template for leaf nodes -->
				<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
					<!-- use a disabled button to provide padding for tree leaf -->
					<button class="mat-button" mat-icon-button disabled><div></div></button>
					<div class="col-md-12">
						<div class="col-md-4">
							<span class="{{ node.className }}"></span>
							<ng-template [ngIf]="node.url" [ngIfElse]="elseBlock">
								<a href="{{ node.url }}" target="_blank">&nbsp;{{ node.name }}</a>
							</ng-template>
							<ng-template #elseBlock>&nbsp;{{ node.name }}</ng-template>
						</div>
						<div class="nodeicons col-md-3 version-info-{{ node.type.toLowerCase() }}">{{ node.extraInfo }}</div>
					</div>
				</mat-tree-node>
				<!-- This is the tree node template for expandable nodes -->
				<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
					<button class="mat-button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
						<mat-icon class="mat-icon-rtl-mirror">
							{{ requirementTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
						</mat-icon>
					</button>
					<div class="col-md-12">
						<div class="col-md-5">
							<span class="{{ node.className }}"></span>
							<ng-template [ngIf]="node.url" [ngIfElse]="elseBlock">
								<a href="{{ node.url }}" target="_blank">&nbsp;{{ node.name }}</a>
							</ng-template>
							<ng-template #elseBlock>&nbsp;{{ node.name }} {{ node.type === 'Requirement' ? '(IRMA v' + node.version + ')' : '' }}</ng-template>
						</div>
						<div class="nodeicons col-md-3 version-info-{{ node.type.toLowerCase() }}">{{ node.extraInfo }}</div>
					</div>
				</mat-tree-node>
			</mat-tree>
		</div>
	</ng-template>
</div>
