<div class="ng-tns-c140-2 ng-star-inserted">
	<mat-card class="mat-card">
		<div routerLink="/testresult/testrun/{{ testRunViewModel?.TestRun?.TestRunUid }}" style="cursor: pointer; word-wrap: break-word">
			Test run: {{ testRunViewModel?.TestRun?.ExecutionTime | date: 'yyyy-MM-dd HH:mm:ss' }}
			<br />
			{{ testRunViewModel?.TestRun?.Environment }} - {{ testRunViewModel?.TestRun?.RegBy }}
		</div>
		<mat-tree #tree [dataSource]="treeDataSource" [treeControl]="treeControl">
			<!-- This is the tree node template for leaf nodes -->
			<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="margin-bottom: 10px" (click)="setActiveNode(node)">
				<!-- use a disabled button to provide padding for tree leaf -->
				<button mat-icon-button disabled><div></div></button>
				<span class="{{ node.className }}"></span>
				&nbsp;
				<ng-template [ngIf]="node?.url" [ngIfElse]="elseBlock">
					<a href="{{ node.url }}">&nbsp;{{ node?.itemno }} - {{ node?.name }}</a>
				</ng-template>
				<ng-template #elseBlock>&nbsp;{{ node?.itemno }} - {{ node?.name }}</ng-template>
			</mat-tree-node>
			<!-- This is the tree node template for expandable nodes -->
			<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding style="margin-top: 20px">
				<button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
					<mat-icon class="mat-icon-rtl-mirror">
						{{ treeControl?.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
					</mat-icon>
				</button>
				<span class="{{ node.className }}"></span>
				&nbsp;{{ node?.itemno }} - {{ node?.name }}
			</mat-tree-node>
		</mat-tree>
	</mat-card>
</div>
