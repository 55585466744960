import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { SOPS } from 'src/models/sops';
import { SOPS_QUERY } from 'src/graphql/queries/abstraction-layer-queries';

import { SopsBaseService } from './sops.base.service';
import { ApolloService } from './apollo.service';

@Injectable({ providedIn: 'root' })
export class SopsGraphQLService extends SopsBaseService {
	constructor(private apolloService: ApolloService) {
		super();
	}

	public getSOPS(runUid: string): Observable<SOPS> {
		return this.apolloService.apolloClients$.pipe(
			switchMap(apolloClients => {
				return apolloClients['abstractionLayerClient'].query<{ resultsSopsForTestRun: SOPS }>({
					query: SOPS_QUERY,
					variables: { TestRunUid: runUid },
				});
			}),
			map(result => {
				if (result.data && result.data.resultsSopsForTestRun) {
					return result.data.resultsSopsForTestRun;
				} else {
					return new SOPS();
				}
			}),
			shareReplay(1),
		);
	}
}
