import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAccordion } from '@angular/material/expansion';

import { Subscription } from 'rxjs';

import { TestSuite } from 'src/models/testRun';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';

@Component({
	selector: 'app-test-suite',
	templateUrl: './test-suite.component.html',
	styleUrls: ['./test-suite.component.scss'],
})
export class TestSuiteComponent implements OnInit, OnDestroy {
	private subscription: Subscription;

	@ViewChild(MatAccordion) accordion: MatAccordion;

	queryParamUid: string;

	queryParamVersion: string;

	testSuite: TestSuite;

	gettingTestSuite: boolean;

	testSuiteExist: boolean;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private testSuiteBaseService: TestSuiteBaseService,
	) {}

	ngOnInit(): void {
		if (this.router.url.includes('testsuitedetails')) {
			this.getParamUidFromUrlParameter();
			this.getParamVersionFromUrlParameter();
			this.getTestSuite();
		}
	}

	private getTestSuite(): void {
		this.gettingTestSuite = true;
		this.testSuiteExist = true;
		if (this.queryParamVersion !== undefined) {
			this.subscription = this.testSuiteBaseService.getTestSuite(this.queryParamUid, +this.queryParamVersion).subscribe(ts => {
				if (ts.TestSuiteUid === undefined || ts.TestSuiteUid === null) {
					this.testSuiteExist = false;
				}
				this.testSuite = ts;
				this.gettingTestSuite = false;
			});
		} else {
			this.subscription = this.testSuiteBaseService.getLatestTestSuite(this.queryParamUid).subscribe(ts => {
				if (ts.TestSuiteUid === undefined || ts.TestSuiteUid === null) {
					this.testSuiteExist = false;
				}
				this.testSuite = ts;
				this.gettingTestSuite = false;
			});
		}
	}

	private getParamUidFromUrlParameter() {
		this.route.queryParams.subscribe(params => {
			this.queryParamUid = params['uid'];
		});
	}

	private getParamVersionFromUrlParameter() {
		this.route.queryParams.subscribe(params => {
			this.queryParamVersion = params['version'];
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
