<div *ngIf="steps?.length > 0">
	<h4>Test case steps</h4>
	<div>
		<table mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="Step number">
				<th mat-header-cell *matHeaderCellDef width="50px">Step</th>
				<td mat-cell *matCellDef="let element">{{ element.StepNo === 0 ? '' : element.StepNo }}</td>
			</ng-container>
			<ng-container matColumnDef="Precondition">
				<th mat-header-cell *matHeaderCellDef>Precondition</th>
				<td mat-cell *matCellDef="let element">{{ element.Precondition }}</td>
			</ng-container>
			<ng-container matColumnDef="Stimuli">
				<th mat-header-cell *matHeaderCellDef>Stimuli</th>
				<td mat-cell *matCellDef="let element">{{ element.Stimuli }}</td>
			</ng-container>
			<ng-container matColumnDef="Expected response">
				<th mat-header-cell *matHeaderCellDef>Expected response</th>
				<td mat-cell *matCellDef="let element">{{ element.ExpectedResponse }}</td>
			</ng-container>
			<ng-container matColumnDef="Postcondition">
				<th mat-header-cell *matHeaderCellDef>Postcondition</th>
				<td mat-cell *matCellDef="let element">{{ element.Postcondition }}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</div>
</div>
<div *ngIf="steps?.length === 0" class="normal-text">There are no steps for this test case</div>
