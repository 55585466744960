// based of https://www.equinox.co.nz/blog/feature-toggles-for-angular-uis

import { Injectable } from '@angular/core';

import { Observable, map, shareReplay, switchMap } from 'rxjs';

import * as LDClient from 'launchdarkly-js-client-sdk';
import { TmtLoggerService } from 'tmt-logger';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

/**
 * Service providing Launch Darkly Client
 */
@Injectable({
	providedIn: 'root',
})
export class LaunchDarklyService {
	constructor(
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private ldLogger: LaunchDarklyLogger,
	) {}

	/**
	 * Observable of the LaunchDarkly client, which emits a new client when user data changes.
	 */
	public ldClient$ = this.authService.userData$.pipe(
		map(userData => {
			const context: LDClient.LDContext = {
				key: (userData.userName ?? '') !== '' ? userData.userName : 'anonymous',
				name: (userData.userName ?? '') !== '' ? userData.userName : 'anonymous',
				country: (userData.companyName ?? '') !== '' ? userData.companyName : 'anonymous',
			};

			const options: LDClient.LDOptions = {
				logger: this.ldLogger,
			};

			return LDClient.initialize(this.appConfigService.getConfig().launchDarklyClientId, context, options);
		}),
		shareReplay(1),
	);

	/**
	 * Observable of the LaunchDarkly flags, using the client observable above.
	 * Creates an observable of flag changes in the current client which are then shared with subscribers.
	 */
	public launchDarklyFlags$ = this.ldClient$.pipe(
		switchMap(
			client =>
				new Observable<{ [key: string]: any }>(subscriber => {
					client.on('ready', () => {
						subscriber.next(client.allFlags());
					});

					client.on('change', () => {
						subscriber.next(client.allFlags());
					});
				}),
		),
		shareReplay(1),
	);
}

@Injectable({
	providedIn: 'root',
})
export class LaunchDarklyLogger implements LDClient.LDLogger {
	constructor(private loggerService: TmtLoggerService) {}

	debug = (message: string): void => {
		this.loggerService.logDebug(message);
	};

	error = (message: string): void => {
		this.loggerService.logError(message);
	};

	info = (message: string): void => {
		this.loggerService.logInformation(message);
	};

	warn = (message: string): void => {
		this.loggerService.logWarning(message);
	};
}
