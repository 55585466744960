import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { TmtLoggerService } from 'tmt-logger';

import { TestSuite } from 'src/models/testRun';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';

interface FlatNode {
	expandable: boolean;
	itemno: string;
	name: string;
	version: number;
	parentuid: string;
	regTime: Date;
	regBy: string;
	testSuiteUid: string;
	level: number;
}

@Component({
	selector: 'app-test-suite-testsuites',
	templateUrl: './test-suite-testsuites.component.html',
	styleUrls: ['./test-suite-testsuites.component.scss'],
})
export class TestSuiteTestsuitesComponent implements OnChanges {
	testSuites: TestSuite[];

	@Input() testSuiteUid: string;

	@Input() testSuiteVersion: number;

	displayedColumns: string[] = ['Name', 'Version', 'RegTime', 'RegBy', 'TestSuiteUid', 'GoTo'];

	gettingTestSuites: boolean;

	private transformer = (node: TestSuite, level: number) => {
		return {
			expandable: !!node.TestSuites && node.TestSuites.length > 0,
			itemno: node.ItemNo,
			name: node.Name,
			version: node.Version,
			parentuid: node.ParentUID,
			regTime: node.RegTime,
			regBy: node.RegBy,
			testSuiteUid: node.TestSuiteUid,
			level: level,
		};
	};

	treeControl = new FlatTreeControl<FlatNode>(
		node => node.level,
		node => node.expandable,
	);

	treeFlattener = new MatTreeFlattener(
		this.transformer,
		node => node.level,
		node => node.expandable,
		node => node.TestSuites,
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	constructor(
		private testSuiteBaseService: TestSuiteBaseService,
		private router: Router,
		private loggerService: TmtLoggerService,
	) {
		//Below lines added to be able to refresh this component so link to TS details from TS panel shows correct TS in heading on test-suite comppnent
		//Works together with {onSameUrlNavigation: 'reload'} which is added to app-routing module
		this.router.routeReuseStrategy.shouldReuseRoute = () => {
			return false;
		};
	}

	ngOnChanges(): void {
		if (this.testSuiteVersion !== undefined) {
			this.getTestSuitesForTestSuite(this.testSuiteUid, this.testSuiteVersion);
		} else {
			this.getTestSuitesForLatestTestSuite(this.testSuiteUid);
		}
	}

	private getTestSuitesForTestSuite(tsUid: string, tsVersion: number) {
		this.gettingTestSuites = true;
		this.testSuiteBaseService.getTestSuitesForTestSuite(tsUid, tsVersion).subscribe(
			ts => {
				this.testSuites = ts;
				this.dataSource.data = ts;
				this.gettingTestSuites = false;
			},
			error => {
				this.loggerService.logError(error), (this.gettingTestSuites = false);
			},
		);
	}

	private getTestSuitesForLatestTestSuite(tsUid: string) {
		this.gettingTestSuites = true;
		this.testSuiteBaseService.getTestSuitesForLatestTestSuite(tsUid).subscribe(
			ts => {
				this.testSuites = ts;
				this.dataSource.data = ts;
				this.gettingTestSuites = false;
			},
			error => {
				this.loggerService.logError(error), (this.gettingTestSuites = false);
			},
		);
	}

	public navigateTo(row: any) {
		if (row !== undefined) {
			this.router.navigateByUrl('/testsuitedetails?uid=' + row?.testSuiteUid + '&version=' + row?.version);
		}
	}
}
