import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ApolloModule } from 'apollo-angular';
import { GalleriaModule } from 'primeng/galleria';
import { ErrorHandlerService, LOGGER_CONFIG } from 'tmt-logger';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';

import { HeaderComponent } from 'src/components/header/header.component';
import { AppConfigService } from 'src/services/app-config.service';
import { OffboardComponentBaseService } from 'src/services/offboard-component.base.service';
import { OffboardComponentGraphQLService } from 'src/services/offboard-component.graphql.service';
import { RequirementBaseService } from 'src/services/requirement.base.service';
import { RequirementGraphQLService } from 'src/services/requirement.graphql.service';
import { SopsBaseService } from 'src/services/sops.base.service';
import { SopsGraphQLService } from 'src/services/sops.graphql.service';
import { TestCaseBaseService } from 'src/services/test-case.base.service';
import { TestCaseGraphQLService } from 'src/services/test-case.graphql.service';
import { TestResultsBaseService } from 'src/services/test-results.base.service';
import { TestResultsGraphQLService } from 'src/services/test-results.graphql.service';
import { TestRunBaseService } from 'src/services/test-run.base.service';
import { TestRunGraphQLService } from 'src/services/test-run.graphql.service';
import { TestSpecificationBaseService } from 'src/services/test-specification.base.service';
import { TestSpecificationGraphQLService } from 'src/services/test-specification.graphql.service';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';
import { TestSuiteGraphQLService } from 'src/services/test-suite.graphql.service';
import { TroubleReportBaseService } from 'src/services/trouble-report.base.service';
import { TroubleReportGraphQLService } from 'src/services/trouble-report.graphql.service';
import { JiraFeedbackComponent } from 'src/components/jira-feedback/jira-feedback.component';
import { StartPageModule } from 'src/modules/start-page/start-page.module';
import { TestResultsPageModule } from 'src/modules/test-results-page/test-results-page.module';
import { HighlightPipe } from 'src/pipes/highlight.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [AppComponent, HighlightPipe, HeaderComponent, JiraFeedbackComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		StartPageModule,
		TestResultsPageModule,
		AppRoutingModule,
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		NgxChartsModule,
		MatTabsModule,
		GalleriaModule,
		ApolloModule,
		MenubarModule,
		MenuModule,
		AvatarModule,
		MenuModule,
		ButtonModule,
	],
	providers: [
		provideNgxWebstorage(withNgxWebstorageConfig({ separator: ':', caseSensitive: true }), withLocalStorage(), withSessionStorage()),
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: APP_INITIALIZER,
			useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
			deps: [AppConfigService],
			multi: true,
		},
		{
			provide: LOGGER_CONFIG,
			useFactory: (appConfigService: AppConfigService) => appConfigService.logConfig$,
			deps: [AppConfigService],
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService,
		},
		DatePipe,
		{
			provide: TestRunBaseService,
			useClass: TestRunGraphQLService,
		},
		{
			provide: SopsBaseService,
			useClass: SopsGraphQLService,
		},
		{
			provide: OffboardComponentBaseService,
			useClass: OffboardComponentGraphQLService,
		},
		{
			provide: TestCaseBaseService,
			useClass: TestCaseGraphQLService,
		},
		{
			provide: TestResultsBaseService,
			useClass: TestResultsGraphQLService,
		},
		{
			provide: RequirementBaseService,
			useClass: RequirementGraphQLService,
		},
		{
			provide: TroubleReportBaseService,
			useClass: TroubleReportGraphQLService,
		},
		{
			provide: TestSuiteBaseService,
			useClass: TestSuiteGraphQLService,
		},
		{
			provide: TestSpecificationBaseService,
			useClass: TestSpecificationGraphQLService,
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
