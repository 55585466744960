import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { take } from 'rxjs/operators';

import { TmtLoggerService } from 'tmt-logger';

import { filteredStats } from 'src/models/filteredStats';
import { OverviewItem, OverViewSeries } from 'src/models/overview';
import { TestLevel } from 'src/models/testLevel';
import { DropDownType } from 'src/modules/start-page/components/filter/filter.component';
import { OverviewService } from 'src/services/overview.service';
import { TypesService } from 'src/services/types.service';
import { UsersAndGroupsService } from 'src/services/users-and-groups.service';

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class OverviewComponent implements OnInit {
	view: any[] = [1300, 400];

	totalResultsDistributedOnInputType: OverviewItem[];

	overviewData: OverViewSeries[];

	sumCreatedTA: number;

	xAxisLabel: string = 'Week';

	yAxisLabel: string = 'Number of items';

	dateTime: Date;

	testLevels: TestLevel[];

	orgsList: DropDownType[] = [{ display: 'All', value: 'All' }];

	SelectedFilters: filteredStats;

	colorScheme = {
		domain: ['#16417F', '#D6001C', '#CEB888', '#FA6E23', '#438151', '#82848A', '#A6D1AD'],
	};

	constructor(
		private overviewService: OverviewService,
		private usersAndGroupsService: UsersAndGroupsService,
		private typesService: TypesService,
		private loggerService: TmtLoggerService,
	) {}

	ngOnInit(): void {
		this.getOverviewData();
		this.dateTime = new Date();
		this.getAllTestLevels();

		//settings should be set to ALL for everyone as init.
		this.SelectedFilters = { testGroup: 'All', testLevel: -1, groupOwner: 'All' };
		this.usersAndGroupsService
			.getAllUsers()
			.pipe(take(1))
			.subscribe(users => {
				//Filter out the organizations so they are unique and only 4 letter organizations
				const orgs = users
					.map(usr => usr.organization)
					.filter(s => s.length === 4)
					.filter((v, i, a) => a.indexOf(v) === i)
					.sort();
				const orgsObjects = orgs.map(gr => ({ display: gr, value: gr }));
				this.orgsList = this.orgsList.concat(orgsObjects);
			});
	}

	public getTotalResultsDistributedOnInputType() {
		this.overviewService.getTotalResultsDistributedOnInputType().subscribe(
			data => {
				this.totalResultsDistributedOnInputType = data;
			},
			error => this.loggerService.logError(error),
		);
	}

	private getOverviewData() {
		this.overviewService.getOverviewData().subscribe(
			data => {
				this.overviewData = data;
			},
			error => this.loggerService.logError(error),
		);
	}

	private getAllTestLevels() {
		this.typesService.getAllTestLevels().subscribe(
			data => {
				this.testLevels = [];
				this.testLevels.push({ Id: -1, Name: 'All' });
				this.testLevels = this.testLevels.concat(data);
			},
			error => this.loggerService.logError(error),
		);
	}

	public onSelect(data): void {
		//console.log('Item clicked', JSON.parse(JSON.stringify(data)));
	}

	public onActivate(data): void {
		//console.log('Activate', JSON.parse(JSON.stringify(data)));
	}

	public onDeactivate(data): void {
		//console.log('Deactivate', JSON.parse(JSON.stringify(data)));
	}

	public updateStatsForGroup(event: any) {
		this.SelectedFilters = { ...this.SelectedFilters, groupOwner: event };
		this.getFilteredData();
		//console.log(this.SelectedFilters);
	}

	public updateStatsForTestLevel(event: any) {
		this.SelectedFilters = { ...this.SelectedFilters, testLevel: event };
		this.getFilteredData();
		//console.log(this.SelectedFilters);
	}

	public updateStatsForTestedBy(event: any) {
		this.SelectedFilters = { ...this.SelectedFilters, testGroup: event };
		this.getFilteredData();
		//console.log(this.SelectedFilters);
	}

	private getFilteredData() {
		this.overviewService.getOverviewDataWithFilter(this.SelectedFilters).subscribe(data => {
			this.overviewData = data;
		});
	}

	public axisFormatToInteger(val) {
		if (val % 1 === 0) {
			return val.toLocaleString();
		} else {
			return '';
		}
	}
}
