<p-menubar class="p-header" [model]="items">
	<ng-template pTemplate="start">
		<a routerLink="/">
			<div class="title desktop-only">{{ title }}</div>
			<i class="pi pi-home mobile-only title-icon"></i>
		</a>
	</ng-template>
	<ng-template pTemplate="item" let-item>
		@if (item.route) {
			<a [routerLink]="item.route" class="p-menuitem-link">
				<span [class]="item.icon"></span>
				<span class="ml-2">{{ item.label }}</span>
			</a>
		} @else if (item.url) {
			<a [href]="item.url" class="p-menuitem-link">
				<span [class]="item.icon"></span>
				<span class="ml-2">{{ item.label }}</span>
			</a>
		} @else {
			<div class="p-menuitem-link">
				<span [class]="item.icon"></span>
				<span class="ml-2">{{ item.label }}</span>
				<span class="pi pi-fw pi-angle-down ml-2"></span>
			</div>
		}
	</ng-template>
	<ng-template pTemplate="end">
		<div class="end-items">
			<p-menu #menu [popup]="true">
				<ng-template pTemplate="start">
					@if (authService.userData$ | async; as userData) {
						<div class="session-content">
							@if (!!userData.sessionID) {
								<div>
									<b>{{ userData.userName }}</b>
								</div>
								<div>{{ userData.companyName }}</div>
							}
						</div>
					}
				</ng-template>
			</p-menu>
			<div class="user-icon-container" style="cursor: pointer" (click)="menu.toggle($event)" (keydown)="menu.toggle($event)" tabindex="0">
				<img class="user-icon" alt="Signed in user icon" src="https://www.svgrepo.com/show/384671/account-avatar-profile-user-14.svg" />
			</div>
			<img class="traton-logo" alt="Traton logo" src="theme/assets/logos/svg/TRATON_Logo_RGB_white.svg" />
		</div>
	</ng-template>
</p-menubar>
