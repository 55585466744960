import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AuthService } from 'src/services/auth.service';
import { LaunchDarklyService } from 'src/services/launchdarkly.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	params$: Observable<ParamMap> = this.route.queryParamMap;

	navigationEnded$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		protected authService: AuthService,
		private ldService: LaunchDarklyService,
	) {}

	ngOnInit(): void {
		combineLatest([this.params$, this.authService.isLoggedIn$, this.navigationEnded$, this.ldService.launchDarklyFlags$])
			.pipe(
				filter(([params, isLoggedIn, navigationEnded, ldData]) => {
					return !isLoggedIn;
				}),
				switchMap(([params, isLoggedIn, navigationEnded, ldData]) => {
					const sessionId = params.get('sessionId') ?? '';
					const userName = params.get('username') ?? '';
					const companyName = params.get('company') ?? '';
					return this.authService.DoLogin(sessionId, userName, companyName);
				}),
			)
			.subscribe();
	}
}
