import { Component } from '@angular/core';

@Component({
	selector: 'app-test-results-page',
	templateUrl: './test-results-page.component.html',
	styleUrls: ['./test-results-page.component.scss'],
})
export class TestResultsPageComponent {
	constructor() {}
}
