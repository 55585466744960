import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { TestLevel } from 'src/models/testLevel';

import { BaseAPIService } from './base-api.service';

@Injectable({
	providedIn: 'root',
})
export class TypesService extends BaseAPIService {
	constructor(private http: HttpClient) {
		super();
	}

	public getAllTestLevels(): Observable<Array<TestLevel>> {
		return this.http.get<Array<TestLevel>>(`${environment.baseurl}testlevels`, { withCredentials: true }).pipe(catchError(this.handleError));
	}
}
