<h4>Trouble reports for test result</h4>
<div>
	<table mat-table [dataSource]="dataSource" matSort>
		<!-- ECU Column -->
		<ng-container matColumnDef="Jira Issue">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Jira issue</th>
			<td mat-cell *matCellDef="let element">
				<a href="{{ element.Uri }}" target="_blank">{{ element.Uri }}</a>
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="ValidFrom">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Valid from</th>
			<td mat-cell *matCellDef="let element">{{ element.ValidFrom | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
		</ng-container>

		<!-- Value Column -->
		<ng-container matColumnDef="ValidTo">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Valid to</th>
			<td mat-cell *matCellDef="let element">{{ element.ValidTo | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
