import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { TmtLoggerService } from 'tmt-logger';

import { TestCaseAlternative } from 'src/models/testCase';
import { TestCaseBaseService } from 'src/services/test-case.base.service';

@Component({
	selector: 'app-test-case-alternative',
	templateUrl: './test-case-alternative.component.html',
	styleUrls: ['./test-case-alternative.component.scss'],
})
export class TestCaseAlternativeComponent implements OnChanges {
	alternatives: TestCaseAlternative[];

	@Input() testCaseUid: string;

	@Input() testCaseVersion: number;

	@Output() countTestCaseAlternatives: EventEmitter<any> = new EventEmitter();

	dataSource: MatTableDataSource<TestCaseAlternative>;

	displayedColumns: string[] = ['Properties', 'Version', 'TestGroups', 'TestObjects', 'RegTime', 'RegBy', 'UID'];

	constructor(
		private testCaseBaseService: TestCaseBaseService,
		private loggerService: TmtLoggerService,
	) {}

	ngOnChanges(): void {
		if (this.testCaseVersion !== undefined) {
			this.getAlternativesForTestCase(this.testCaseUid, this.testCaseVersion);
		} else {
			this.getAlternativesForLatestTestCase(this.testCaseUid);
		}
	}

	private getAlternativesForTestCase(tcUid: string, tcVersion: number) {
		this.testCaseBaseService.getAlternativesForTestCase(tcUid, tcVersion).subscribe(
			alt => {
				this.alternatives = alt;
				this.countTestCaseAlternatives.emit(this.alternatives.length);
				const dataSource = new MatTableDataSource<TestCaseAlternative>(this.alternatives);
				//   dataSource.sort = this.sort;
				// dataSource.paginator = this.paginator;
				this.dataSource = dataSource;
			},
			error => {
				this.loggerService.logError(error);
			},
		);
	}

	private getAlternativesForLatestTestCase(tcUid: string) {
		this.testCaseBaseService.getAlternativesForLatestTestCase(tcUid).subscribe(
			alt => {
				this.alternatives = alt;
				const dataSource = new MatTableDataSource<TestCaseAlternative>(this.alternatives);
				//   dataSource.sort = this.sort;
				// dataSource.paginator = this.paginator;
				this.dataSource = dataSource;
			},
			error => {
				this.loggerService.logError(error);
			},
		);
	}

	public applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
